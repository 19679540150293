import { Box, Divider, Grid } from '@mui/material'
import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const Home = () => {
    return (
        <div >
            <Box className="benefit-section">
                <div className='main-head' >
                    <div className='title'>
                        <Divider className='title-line' />
                        <h1>Benefits</h1>
                        <Divider className='title-line' />
                    </div>
                </div>
                <Grid container spacing={2} className='benefit-grid'>
                    <Grid item xs={6} sm={6} md={4} lg={3}>
                        <Card sx={{ borderRadius: '0' }} className='benefit-card'>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    src={require('../../../assets/images/card-1.png')}
                                    alt="card"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5">
                                        Reach to millions of online customers
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={6}  sm={6} md={4} lg={3}>
                        <Card sx={{ borderRadius: '0' }} className='benefit-card'>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    src={require('../../../assets/images/card_img-2.png')}
                                    alt="card"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5">
                                    Secure, Timely and Hassle-free Payments 14 Days
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={6}  sm={6} md={4} lg={3}>
                        <Card sx={{ borderRadius: '0' }} className='benefit-card'>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    src={require('../../../assets/images/card_img-3.png')}
                                    alt="card"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5">
                                    Fast, Stress-Free Pick Up and Delivery
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={6}  sm={6} md={4} lg={3}>
                        <Card sx={{ borderRadius: '0' }} className='benefit-card'>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    src={require('../../../assets/images/card_img-4.png')}
                                    alt="card"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5">
                                    Free and Easy listing of Products
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={6}  sm={6} md={4} lg={3}>
                        <Card sx={{ borderRadius: '0' }} className='benefit-card'>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    src={require('../../../assets/images/card_img-5.png')}
                                    alt="card"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5">
                                    Pay only when you sell
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={6}  sm={6} md={4} lg={3}>
                        <Card sx={{ borderRadius: '0' }} className='benefit-card'>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    src={require('../../../assets/images/card_img-6.png')}
                                    alt="card"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5">
                                    Training
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </Grid>
               
            </Box>
            <Box className="selling-section">
                <div >
                    <div className='selling-text-section'>
                    <h1>Selling on Jebelz is easy</h1>
                    </div>
                    <div className='checkbox-wrapper'>
                        <div className="checkbox-text">
                        <CheckBoxOutlinedIcon style={{ color: 'white' }}/>
                        <h5>Trade License </h5>
                        </div>
                        <div className="checkbox-text">
                        <CheckBoxOutlinedIcon style={{ color: 'white' }}/>
                        <h5>VAT Certificate </h5>
                        </div>
                        <div className="checkbox-text">
                        <CheckBoxOutlinedIcon style={{ color: 'white' }}/>
                        <h5>Bank Account </h5>
                        </div>
                        <div className="checkbox-text">
                        <CheckBoxOutlinedIcon style={{ color: 'white' }}/>
                        <h5>1 Product </h5>
                        </div>
                    </div>
                </div>
            </Box>
            <Box className="bottom-section">
                <div className='text-section'>
                    <h2>HOW IT WORKS</h2>
                    <h5>Doing Online Business On Jebelz is Simple</h5>
                </div>
                <div className='image-section'>
                    <img src={require("../../../assets/images/home_bottom.png")} alt='how it works'/>
                </div>
            </Box>
        </div>
    )
}

export default Home