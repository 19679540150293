import { gql } from "@apollo/client";

export const DASHBOARD_QUERY = gql`
  query {
    dashboard {
      total_orders_count
      pending_orders_count
      processing_orders_count
      pending_percent
      top_selling_category {
        name
        count
      }
      top_products {
        name
        count
      }
      latest_orders {
        increment_id
        name
        created_at
        status
        price
        qty
        sub_total
      }
      lifetime_sales
      store
      currency
    }
  }
`;