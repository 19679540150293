import { gql } from "@apollo/client";

export const ORDER_CONFIRM = gql`
  mutation SellerOrderAction($action: String!, $status: String!, $order_number: String!, $item_id: Int!) {
    sellerOrderAction(action: $action, status: $status, order_number: $order_number, item_id: $item_id) {
      order_number
      date
      status
      product_name
      product_sku
      product_image
      price
      qty
      sub_total
      currency
    }
  }
`;

export const ORDER_GENERATE_AWB = gql`
  mutation SellerOrderAction($action: String!, $status: String!, $order_number: String!, $item_id: Int!) {
    sellerOrderAction(action: $action, status: $status, order_number: $order_number, item_id: $item_id) {
      order_number
      date
      status
      product_name
      product_sku
      product_image
      price
      qty
      sub_total
      currency
    }
  }
`;

export const ORDER_PICKUP_REQUEST = gql`
  mutation SellerOrderAction($action: String!, $status: String!, $order_number: String!, $item_id: Int!) {
    sellerOrderAction(action: $action, status: $status, order_number: $order_number, item_id: $item_id) {
      order_number
      date
      status
      product_name
      product_sku
      product_image
      price
      qty
      sub_total
      currency
    }
  }
`;

export const ORDER_CANCEL = gql`
  mutation SellerOrderAction($action: String!, $status: String!, $order_number: String!, $item_id: Int!) {
    sellerOrderAction(action: $action, status: $status, order_number: $order_number, item_id: $item_id) {
      order_number
      date
      status
      product_name
      product_sku
      product_image
      price
      qty
      sub_total
      currency
    }
  }
`;