import React, { useState } from 'react'
import "./_InfoPoints.scss"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface InfoPointsProps {
    title: string;
    detailText: string;
}

const InfoPoints: React.FC<InfoPointsProps> = ({title, detailText}) => {
    const [btnChange, setBtnChange] = useState(false)

    const handleBtn = () => {
        setBtnChange(!btnChange)
    }

    return (
        <>
            <div className='main-div'>
                <div className='upper' >
                    <button onClick={handleBtn} className="btn-box">
                        {!btnChange ? <AddIcon className="icon-btn" /> : <RemoveIcon className="icon-btn" />}
                    </button>
                    <h5 className='title'>{title}</h5>
                </div>
                {
                    btnChange && (
                        <div className='lower'>
                            <p className='detail-text'>{detailText}</p>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default InfoPoints