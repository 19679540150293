import { gql } from "@apollo/client";

export const PAYMENTS_UNPAID =gql`
    query {
        sellerPayments(
                type: "unpaid",
                pageSize:20,
                currentPage: 1
        )
        {
            order_number
            item_id
            date
            status
            product_name
            product_sku
            product_image
            item_price
            qty
            sub_total
            currency
            item_status
            comission
            payout
            payout_id
            total_payout
            item_status
            download
            currency
        }
    }
`;

export const PAYMENTS_PAID =gql`
    query {
        sellerPayments(
                type: "paid",
                pageSize:20,
                currentPage: 1
        )
        {
            order_number
            item_id
            date
            status
            product_name
            product_sku
            product_image
            item_price
            qty
            sub_total
            currency
            item_status
            comission
            payout
            payout_id
            total_payout
            item_status
            download
            currency
        }
    }
`;
