import { gql } from "@apollo/client";

export const SEARCH_PRODUCT = gql`
query SellerProducts($searchByName: String!, $pageSize: Int!, $currentPage: Int!) {
    sellerProducts(search_by_name: $searchByName, pageSize: $pageSize, currentPage: $currentPage) {
      sku
      status
      name
      price
      image
      qty
      ean
      weight
      image_gallery {
        url
    }
    }
  }
`;

export const EDIT_PRICE_QUANTITY = gql`
query SellerProducts($pageSize: Int!, $currentPage: Int!) {
  sellerProducts(pageSize: $pageSize, currentPage: $currentPage)
  {
    sku
    status
    name
    price
    image
    qty 
  }
}
`;

export const EDIT_PRICE_QUANTITY_LIST = gql`
query sellerProductsList($pageSize: Int!, $currentPage: Int!, $searchProductTerm: String) {
  sellerProductsList(pageSize: $pageSize, currentPage: $currentPage, type:"seller_products", searchProductTerm: $searchProductTerm) 
  {
      items {
            sku
            status
            name
            price
            image
            qty 
        }
        pageInfo {
            page_size
            current_page
            total_pages
        }
  }
}
`;

export const BULK_PRODUCT_DOWNLOAD = gql`
query sellerProductDownload($downloadAll: String) {
  sellerProductDownload(downloadAll: $downloadAll) 
  {
      download_url
  }
}
`;