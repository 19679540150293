import React from 'react'
import { Link } from 'react-router-dom';
import './NewTopbar.scss';
import { Button, Typography } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import CommonToast from '../ToastMessage';
import Toaster from '../ToastMessage';
import CountryFlag from './CountryFlag';

const convertTitle = (path: any) => {
    const parts = path.split('/');
    const lastPart = parts[parts.length - 1];

    // Map last part to a more readable format
    const titleMappings: { [key: string]: string } = {
        'dashboard': 'Dashboard',
        'product': "Product",
        'orders': 'Orders',
        'returns': 'Returns',
        'payment': 'Payments',
        'settings': 'Settings',
        'edit-bank-details': 'Edit Bank Details',
        'edit-name': 'Edit Name',
        'edit-email': 'Edit Email',
        'change-password': 'Change Password',
        'edit-pickup-details': 'Edit PickUp Details'
    };

    // If there's a mapping for the last part, return it; otherwise, return the original last part
    return titleMappings[lastPart] || lastPart.charAt(0).toUpperCase() + lastPart.slice(1);
};

const Topbar = () => {
    // const title = window.location.pathname.split("/");
    const title = convertTitle(window.location.pathname);
    const handleFailure = () => {
      
      };
    return (
        <>
            <header className='header'>
                <div className='header_inner'>
                    <div className='left_part'>
                        <button className='btn btn-light btn_close d-lg-none d-md-block' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg>
                        </button>
                        {
                            title[title.length - 1] === 'dashboard' ? (
                                <Button className='topbar-btn'>
                                    <Typography className='topbar-btn-text'>Ask Question</Typography>
                                </Button>
                            ) : (
                                <Typography variant='h4' className='main-titles'>{title}</Typography>
                            )
                        }
                    </div>
                    <div className="right_part">
                        <div className='right-sidebar'>
                            <CountryFlag />
                            <div className='icons' onClick={handleFailure}>
                                <NotificationsIcon />
                                <span>0</span>
                            </div>
                            <div className='icons'>
                                < PersonIcon />
                            </div>
                        </div>
                    </div>
                </div>



            </header>
        </>
    )
}

export default Topbar
