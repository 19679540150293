import "../../../../src/mainseller.scss";
import { Divider } from '@mui/material'
import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#10CDF2",
    color: "#000000",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  width: "50%",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name: string, percentage: string) {
  return { name, percentage };
}

const rows = [
  createData("Coffee Machines", "14%"),
  createData("Coffee Accessories", "14%"),
  createData("Coffee Consumables", "14%"),
  createData("Coffee Machines", "14%"),
  createData("Beverage Equipments", "14%"),
  createData("Dish Washing Equipments", "14%"),
  createData("Ice Equipments", "14%"),
  createData("Refrigeration", "14%"),
  createData("Commercial Oven", "14%"),
];

export function CustomizedTables() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400, border: '1px solid #E5E4E4 ', borderRadius: '0' }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell className="table-title-name">Category</StyledTableCell>
            <StyledTableCell className="table-title-name">Referral (%)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell className="table-content-name" component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell className="table-content-name" align="right">{row.percentage}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


const ReferralFee = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='referral-main center-div' >
      <div className='main-head' >
        <div className='title'>
          <Divider className='title-line'/>
          <h1>Referral Fee</h1>
          <Divider className='title-line'/>
        </div>
      </div>
      <div className='bottom-section bottom-section-referral'>
        <CustomizedTables/>
      </div>
    </div>
  )
}

export default ReferralFee

