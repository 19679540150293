import { useMutation } from '@apollo/client';
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { settingsMutation } from '../../../Operations/Mutations';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toaster from '../../../Components/ToastMessage';
import { CountryContext } from '../../..';

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const loggedInToken = localStorage.getItem("loggedInCust");
  const navigate = useNavigate()

  const {store} = useContext(CountryContext);

  const [updatePassword] = useMutation(
    settingsMutation.UPDATE_PASSWORD_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );


  const saveNewPassword = async () => {
    try {
      if (currentPassword === newPassword) {
        toast.error('New password must be different from the current password', {
          position: toast.POSITION.TOP_RIGHT
      });
        return;
      }
      if (newPassword !== confirmPassword) {
        toast.error('New password and confirm password do not match', {
          position: toast.POSITION.TOP_RIGHT
      });
        return;
      }
      const response = await updatePassword({
        variables: {
          currentPassword: currentPassword,
          newPassword: newPassword
        }
      });
      const data = response.data;
      console.log("data after saving:", data)
      toast.success('Successfully updated Password', {
        position: toast.POSITION.TOP_RIGHT
    });
    } catch (error) {
      toast.error('Error updating the Password', {
        position: toast.POSITION.TOP_RIGHT
    });
    }
  };
  // const saveNewPassword = () => {
  //   if (currentPassword === newPassword) {
  //     setPasswordError("New password must be different from the current password.");
  //     console.log("error--", passwordError)
  //     return;
  //   }

  //   if (newPassword !== confirmPassword) {
  //     setPasswordError("New password and confirm password do not match.");
  //     console.log("error--", passwordError)
  //     return;
  //   }
  //   console.log("Password saved successfully, new password: ", newPassword);
  //   setPasswordError('');
  // };

  const backToSettings = () => {
    navigate("/dashboard/settings")
  }

  return (
    <Box>
      <Toaster/>
      <Grid container spacing={2} className="change-name change-password">
        <Grid item xs={12} sm={12} md={12} className='settings-header'>
          <Typography variant='h5'>Change Password</Typography>
        </Grid>
        <Divider className='divider' />
        <Grid item xs={12} sm={12} md={12} className='title text-title'>
          <Typography variant='h6'>Use the form below to change the password for your Jebelz account</Typography>
        </Grid>
        <Grid container spacing={2} xs={12} sm={12} md={12} lg={9} className='form-details'>
          <Grid item xs={12} sm={12} md={8} className='name'>
            <label>Current password :</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='currentPassword'
              placeholder='Enter current Password'
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <label>New password:</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='newPassword'
              placeholder='Enter new Password'
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <label>Confirm new password:</label>
            <TextField
              variant="outlined"
              type='password'
              className='input-field'
              name='confirmPassword'
              placeholder='Confirm new Password'
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className='btn-grid'>
          <Button className='btn btn-back' onClick={backToSettings}><Typography variant='h6'>Back</Typography></Button>
          <Button className='btn' onClick={saveNewPassword}><Typography variant='h6'>Save Changes</Typography></Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ChangePassword