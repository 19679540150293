// import { List, ListItem, ListItemText } from '@mui/material'
// import React, { useState } from 'react'
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';

// const MobileMenubar = () => {
//     const [menuOpen, setMenuOpen] = useState(false);
//     const [selectedMenuItem, setSelectedMenuItem] = useState('');

//     const toggleMenu = () => {
//         setMenuOpen(!menuOpen);
//       };
  
//       const handleMenuItemClick = (itemName: string) => {
//         setSelectedMenuItem(itemName);
//         setMenuOpen(false);
//       }

//   return (
//     <div className="mobile-tabs-menu">
//             <div className='active-tabs-text'>{selectedMenuItem}</div>
//             <div className='menu-toggle-icon' onClick={toggleMenu}>
//             {menuOpen ? <CloseIcon /> : <MenuIcon />}
//             </div>
//             {
//                menuOpen && (
//             <div className="mobile-menu-list">
//                <List className='list-wrap'>
//                         <ListItem
//                            className={`listmenu-items ${selectedMenuItem === 'Add a Product' ? 'active' : ''}`}
//                            onClick={() => handleMenuItemClick('Add a Product')}
//                         >
//                      <ListItemText className='listmenu-text'>
//                      Add a Product
//                      </ListItemText>
//                   </ListItem>
//                   <ListItem className={`listmenu-items ${selectedMenuItem === 'Add product by Jebelz ID' ? 'active' : ''}`}
//                     onClick={() => handleMenuItemClick('Add product by Jebelz ID')}>
//                      <ListItemText className='listmenu-text'>
//                      Add product by Jebelz ID
//                      </ListItemText>
//                   </ListItem>
//                   <ListItem className={`listmenu-items ${selectedMenuItem === 'Bulk Upload' ? 'active' : ''}`}  
//                   onClick={() => handleMenuItemClick('Bulk Upload')}>
//                      <ListItemText className='listmenu-text'>
//                      Bulk Upload
//                      </ListItemText>
//                   </ListItem>
//                   <ListItem className={`listmenu-items ${selectedMenuItem === 'Easy Edit Price & Quantity' ? 'active' : ''}`} 
//                    onClick={() => handleMenuItemClick('Easy Edit Price & Quantity')}>
//                      <ListItemText className='listmenu-text'>
//                      Easy Edit Price & Quantity
//                      </ListItemText>
//                   </ListItem>
//                   <ListItem className={`listmenu-items ${selectedMenuItem === 'Bulk Price & Quantity Update' ? 'active' : ''}`}  
//                   onClick={() => handleMenuItemClick('Bulk Price & Quantity Update')}>
//                      <ListItemText className='listmenu-text'>
//                      Bulk Price & Quantity Update
//                      </ListItemText>
//                   </ListItem>
//                </List>
//             </div>
//                )
//             }
//          </div>
//   )
// }

// export default MobileMenubar


import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { List, ListItem, ListItemText } from '@mui/material';

const MobileMenuBar = ({ menuItems, onItemClick, selectedMenuItem }: any) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = (itemName: any) => {
    onItemClick(itemName);
    setMenuOpen(false);
  };

  return (
    <div className="mobile-tabs-menu">
      <div className='active-tabs-text'>{selectedMenuItem}</div>
      <div className='menu-toggle-icon' onClick={toggleMenu}>
        {menuOpen ? <CloseIcon /> : <MenuIcon />}
      </div>
      {menuOpen && (
        <div className="mobile-menu-list">
          <List className='list-wrap'>
            {menuItems.map((item: any) => (
              <ListItem
                key={item}
                className={`listmenu-items ${selectedMenuItem === item ? 'active' : ''}`}
                onClick={() => handleMenuItemClick(item)}
              >
                <ListItemText className='listmenu-text'>
                  {item}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </div>
  );
}

export default MobileMenuBar;
