// import React, { useState } from 'react';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// function Toaster(props: any) {
//     // eslint-disable-next-line @typescript-eslint/no-unused-vars
//     const [msg, setMsg] = useState(props.msg)

// console.log("msg", msg)                                                                                 
//     return (
//         <div>
//             {msg === "success" ? toast.success('Success Notification !', {
//                 position: toast.POSITION.TOP_LEFT
//             }) : toast.error('Error Notification !', {
//                 position: toast.POSITION.TOP_LEFT
//             })}
//             {/* <ToastContainer /> */}
//         </div>
//     );
// }
// export default Toaster;


//  New Toaster component:
import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Toaster(props: any) {
    // const [toastShown, setToastShown] = useState(false);

    // useEffect(()=>{
    //     console.log("mesg from toaster:", props.msg)
    // })

    // useEffect(() => {
    //     if (!toastShown) {
    //         if (props.msg === "success") {
    //             toast.success('Success Notification !', {
    //                 position: toast.POSITION.TOP_RIGHT
    //             });
    //         } else if (props.msg === "error") {
    //             toast.error('Error Notification !', {
    //                 position: toast.POSITION.TOP_RIGHT
    //             });
    //         } else if(props.msg === "bulkUploadSuccess") {
    //             toast.success('Bulk Upload sheet successfully uploaded !', {
    //                 position: toast.POSITION.TOP_RIGHT
    //             });
    //         } else if(props.msg === "bulkUploadError") {
    //             toast.error('Error Uploading Bulk Upload sheet !', {
    //                 position: toast.POSITION.TOP_RIGHT
    //             });
    //         }
    //         setToastShown(true);
    //     }
    // }, [props.msg, toastShown]);

    return (
        <div>
            <ToastContainer />
        </div>
    );
}

export default Toaster;
