import { Box, Grid, Typography, Divider, TextField, Button } from '@mui/material'
import React, { useContext, useState } from 'react'
import "./Settings.scss"
import { useMutation } from '@apollo/client';
import { settingsMutation } from '../../../Operations/Mutations';
import { toast } from 'react-toastify';
import Toaster from '../../../Components/ToastMessage';
import { useNavigate } from 'react-router-dom';
import { CountryContext } from '../../..';

const EditName = () => {
    const [editedName, setEditedName] = useState({firstName : "", lastName: ""});
    const navigate = useNavigate()
    const loggedInToken = localStorage.getItem("loggedInCust");

    const {store} = useContext(CountryContext);

    const [updateName] = useMutation(
      settingsMutation.UPDATE_PICKUP_MUTATION,
      {
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store,
          },
        },
      }
    );

    const saveEditedName = async () => {
        console.log("editedName:", editedName)
        try{
          const response = await updateName({
            variables: {
              input : {
                companyInformation: [{
                  firstname: editedName.firstName,
                  lastname: editedName.lastName,
              }]
              }
            }    
          });
          toast.success('Successfully Changed Name', {
            position: toast.POSITION.TOP_RIGHT
        });
          console.log("response from APi: ", response)
          setEditedName({firstName : "", lastName: ""})
        }catch(error){
          toast.error('Error while changing the name', {
            position: toast.POSITION.TOP_RIGHT
        });
          console.log("error:", error)
        }
      }

      const backToSettings = () => {
        navigate("/dashboard/settings")
      }
      
  return (
    <Box sx={{ width: '100%' }} className="edit-name-box">
      <Toaster/>
        <Grid container spacing={2} className="change-name">
                <Grid item xs={12} sm={12} md={12} className='settings-header'>
                  <Typography variant='h5'>Change your Name</Typography>
                </Grid>
                <Divider className='divider' />
                <Grid container spacing={2} xs={12} sm={12} md={12} lg={9} className='form-details'>
                  <Grid item xs={12} sm={12} md={12} className='title'>
                    <Typography variant='h6'>If you want to change the name associated with your Jebelz seller account, you may do so below. Be sure to click the Save Changes button when you are done.</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>First Name:</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='firstName'
                      placeholder='Enter First Name'
                      value={editedName.firstName}
                      onChange={(e) => setEditedName({ ...editedName, firstName: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>Last Name:</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='lastName'
                      placeholder='Enter Last Name'
                      value={editedName.lastName}
                      onChange={(e) => setEditedName({ ...editedName, lastName: e.target.value })}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8} className='btn-grid'>
                  <Button className='btn btn-back' onClick={backToSettings}><Typography variant='h6'>Back</Typography></Button>
                  <Button className='btn' onClick={saveEditedName}><Typography variant='h6'>Save Changes</Typography></Button>
                </Grid>
              </Grid>
    </Box>
  )
}

export default EditName