import { gql } from "@apollo/client";

export const CREATE_SELLER_MUTATION = gql`
mutation CreateSeller($input: CreateSellerInput!) {
    createSeller(input: $input) {
      seller {
        firstname
        lastname
        email
        is_subscribed
      }
    }
  }
`;