import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
mutation login($email: String!, $password: String!) {
    generateCustomerToken(
        email: $email
        password: $password
      ) {
        token
      }
  }
`;