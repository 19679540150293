import { useMutation } from '@apollo/client';
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { settingsMutation } from '../../../Operations/Mutations';
import { toast } from 'react-toastify';
import Toaster from '../../../Components/ToastMessage';
import { useNavigate } from 'react-router-dom';
import { CountryContext } from '../../..';

const EditBankDetails = () => {
  const [formDataForBank, setFormDataForBank] = useState({
    bankAccountName: '',
    bankName: '',
    ibanNumber: '',
    accountNumber: '',
    swiftCode: '',
    branchName: ''
  });

  const loggedInToken = localStorage.getItem("loggedInCust");
  const navigate = useNavigate()

  const {store} = useContext(CountryContext);

  const [updateBankDetails] = useMutation(
    settingsMutation.UPDATE_BANK_DETAIL_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const handleBankFormData = (e: any) => {
    const { name, value } = e.target;
    setFormDataForBank({ ...formDataForBank, [name]: value });
  };

  const formForApi = {
    bankDetails:[{
      bank_name: formDataForBank.bankName,
      bank_branch_number: formDataForBank.branchName,
      bank_account_name: formDataForBank.bankAccountName,
      bank_account_number: formDataForBank.accountNumber,
      bank_account_iban: formDataForBank.ibanNumber,
      bank_swift_code: formDataForBank.swiftCode,
    }]
  }

  const saveBankFormData = async () => {
    try{
      const response = await updateBankDetails({
        variables : {
          input : formForApi
        //   input : {
        //     bankDetails: [{
        //         bank_name: "NBD 1111",
        //         bank_branch_number: "12212",
        //         bank_account_name: "Noman",
        //         bank_account_number: "AE54545",
        //         bank_account_iban: "IBANAE54545",
        //         bank_swift_code: "AB54"
        //     }]
        // }
        }
      });
      toast.success('Successfully updated Bank details', {
        position: toast.POSITION.TOP_RIGHT
    });
      console.log("Added Details:", response)
    } catch(error){
      toast.error('Error updating Bank details', {
        position: toast.POSITION.TOP_RIGHT
    });
    }
  };

  const backToSettings = () => {
    navigate("/dashboard/settings")
  }

  return (
    <Box>
      <Grid container spacing={2} className="change-name bank-details">
        <Toaster/>
        <Grid item xs={12} sm={12} md={12} className='settings-header'>
          <Typography variant='h5'>Bank Account Details</Typography>
        </Grid>
        <Divider className='divider' />
        <Grid item xs={12} sm={12} md={12} className='title text-title'>
          <Typography variant='h6'>Use the form below to change the Bank Account Details for your Jebelz account</Typography>
        </Grid>
        <Grid container spacing={2} xs={12} sm={12} md={12} lg={9} className='form-details'>
          <Grid item xs={12} sm={12} md={6} className='name'>
            <label>Bank Account Name :</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='bankAccountName'
              value={formDataForBank.bankAccountName}
              onChange={handleBankFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className='name'>
            <label>Bank Name</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='bankName'
              value={formDataForBank.bankName}
              onChange={handleBankFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className='name'>
            <label>IBAN number</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='ibanNumber'
              value={formDataForBank.ibanNumber}
              onChange={handleBankFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className='name'>
            <label>Account number</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='accountNumber'
              value={formDataForBank.accountNumber}
              onChange={handleBankFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className='name'>
            <label>SWIFT code:</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='swiftCode'
              value={formDataForBank.swiftCode}
              onChange={handleBankFormData}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} className='name'>
            <label>Branch Number:</label>
            <TextField
              variant="outlined"
              className='input-field'
              name='branchName'
              value={formDataForBank.branchName}
              onChange={handleBankFormData}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className='btn-grid'>
          <Button className='btn-back btn' onClick={backToSettings}>
            <Typography variant='h6'>Back</Typography>
          </Button>
          <Button className='btn' onClick={saveBankFormData}>
            <Typography variant='h6'>Save Changes</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EditBankDetails