import { gql } from "@apollo/client";

export const SETTINGS_MAINPAGE = gql`
query {
    sellerData {
        companyInformation {
            firstname
            lastname
            email
            company
            contact_number
        }
        pickupLocation {
            city
            region
            postcode
            vendor_address
            country
            google_map
        }
        documents {
            trade_license {
                file
            }
            vat_certificate {
                file
            }
        }
        bankDetails {
            bank_account_name
            bank_name
            bank_branch_number
            bank_account_number
            bank_account_iban
            bank_swift_code
        }
        storeName {
            shop_title
        }
    }
}

`;
