import { gql } from "@apollo/client";

export const UPDATE_PASSWORD_MUTATION = gql`
    mutation ChangeCustomerPassword($currentPassword: String!, $newPassword: String!) {
        changeCustomerPassword(
            currentPassword: $currentPassword,
            newPassword: $newPassword
        ) {
            firstname
            lastname
            email
        }
    }
`;

export const UPDATE_PICKUP_MUTATION = gql`
    mutation UpdateSeller($input: SellerCreateInput!) {
        updateSeller(input: $input) {
            companyInformation {
                firstname
                lastname
                email
                company
                contact_number
            }
            pickupLocation {
                city
                region
                postcode
                vendor_address
                country
                google_map
            }
            documents {
                trade_license {
                    file
                }
                vat_certificate {
                file
                }
            }
            bankDetails {
                bank_account_name
                bank_name
                bank_branch_number
                bank_account_number
                bank_account_iban
                bank_swift_code
            }
            storeName {
                shop_title
            }
        }
    }
`;

export const UPDATE_BANK_DETAIL_MUTATION = gql`
  mutation UpdateSeller($input: SellerCreateInput!) {
    updateSeller(input: $input) {
      bankDetails {
        bank_account_name
        bank_name
        bank_branch_number
        bank_account_number
        bank_account_iban
        bank_swift_code
      }
    }
  }
`;