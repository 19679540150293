import { gql } from "@apollo/client";

export const CREATE_SELLER_ACCOUNT = gql`
mutation createSeller($input: SellerCreateInput!) {
    createSeller(input: $input) {
        companyInformation {
            firstname
            lastname
            email
            company
            contact_number
        }
        pickupLocation {
            city
            region
            postcode
            vendor_address
            country
            company_locality
            google_map
        }
        documents {
            trade_license {
                file
            }
            vat_certificate {
                file
            }
        }
        bankDetails {
            bank_account_name
            bank_name
            bank_branch_number
            bank_account_number
            bank_account_iban
            bank_swift_code
        }
        storeName {
            shop_title
        }
      }
}
`