import { gql } from "@apollo/client";

export const ADD_PRODUCT = gql`
    mutation AddSellerProduct($input: AddSellerProduct!) {
        addSellerProduct(input: $input) {
        sku
        status
        name
        price
        image
        qty
        }
    }
`;

export const UPDATE_PRODUCT = gql`
    mutation AddSellerProduct($input: AddSellerProduct!) {
        addSellerProduct(input: $input) {
        sku
        status
        name
        price
        image
        qty
        }
    }
`;

export const CREATE_PRODUCT = gql`
mutation CreateSellerProduct($input: CreateSellerProduct!) {
    createSellerProduct(input: $input) {
      sku
      status
      name
      price
      image
      qty
    }
  } 
`

export const BULK_UPLOAD = gql`
    mutation ProductBulkUpload($input: ProductBulkUpload!) {
        productBulkUpload(
            input: $input) {
            sku
            status
            name
            price
            image
            qty
        }
    }
`;

export const BULK_UPDATE = gql`
    mutation productBulkUpdate($input: ProductBulkUpload!) {
        productBulkUpdate(
            input: $input) {
            sku
            status
            name
            price
            image
            qty
        }
    }
`;