import { Box, Grid, Typography, Divider, TextField, Button } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const EditEmail = () => {
    const [editedEmail, setEditedEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email: any) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };

    const saveEditedEmail = () => {
        const isEmailValid = validateEmail(editedEmail);
        if (isEmailValid) {
          console.log("saved email", editedEmail);
          setEmailError(false);
        } else {
          console.log("Invalid email address");
          setEmailError(true);
        }
      }

      const backToSettings = () => {
        navigate("/dashboard/settings")
      }

  return (
    <Box sx={{ width: '100%' }}>
        <Grid container spacing={2} className="change-name change-email">
                <Grid item xs={12} sm={12} md={12} className='settings-header'>
                  <Typography variant='h5'>Change your email address</Typography>
                </Grid>
                <Divider className='divider' />
                <Grid container spacing={2} xs={12} sm={12} md={12} lg={9} className='form-details'>
                  <Grid item xs={12} sm={12} md={12} className='title current-email'>
                    <Typography variant='h6'>Current email address:<span>manish.b@ezmartech.com</span></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} className='title text-title'>
                    <Typography variant='h6'>Enter the new email address you would like to associate with your account below. We will send a One Time Password <span>(OTP)</span> to that address.</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>New email address:</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='newEmail'
                      placeholder='Enter new Email'
                      value={editedEmail}
                      onChange={(e) => setEditedEmail(e.target.value)}
                    />
                    {emailError && <Typography variant='caption' color='error'>Invalid email address</Typography>}
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={8} className='btn-grid'>
                  <Button className='btn btn-back' onClick={backToSettings}><Typography variant='h6'>Back</Typography></Button>
                  <Button className='btn' onClick={saveEditedEmail}><Typography variant='h6'>Continue</Typography></Button>
                </Grid>
              </Grid>
    </Box>
  )
}

export default EditEmail