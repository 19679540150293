// import React from 'react'
// import "./Advertise.css"
// import { Button, Divider, Grid, ListItem, TextField, Typography } from '@mui/material'

import { Box, Button, Divider, Grid, List, ListItem, TextField, Typography } from '@mui/material'
import "../../../../src/mainseller.scss";
import { useEffect, useState } from 'react';

const Advertise = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        company: '',
        message: ''
    });

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        // console.log("before--",formData)
        setFormData({ ...formData, [name]: value });
        // console.log("after--",formData)
    };

    const handleFormSubmit = (e: any) => {
        e.preventDefault();
        console.log('Form data submitted:', formData);
        setFormData({
            name: '',
            email: '',
            phoneNumber: '',
            company: '',
            message: ''
        });
    }

    return (
        <>
            <div className='seller-acc-main advertise-main'>
                <div className='main-head' >
                    <div className='title'>
                        <Divider className='title-line' />
                        <h1>Advertise Contact</h1>
                        <Divider className='title-line' />
                    </div>
                </div>
                <form onSubmit={handleFormSubmit}>
                    <Box sx={{
                        border: "1px solid #DEDCDC",
                        backgroundColor: "#FFFFFF"
                    }} className="main-box">
                        <List>
                            <ListItem>
                                <Typography className='title'>
                                    Details
                                </Typography>
                            </ListItem>
                            <Divider />
                            <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                                <Grid item xs={12}>
                                    <label>Name</label>
                                    <TextField
                                        name='name'
                                        value={formData.name}
                                        id="outlined-basic"
                                        variant="outlined"
                                        onChange={handleChange}
                                        className='input-field' />
                                </Grid>
                                <Grid item xs={12}>
                                    <label>Email</label>
                                    <TextField
                                        name='email'
                                        type='email'
                                        value={formData.email}
                                        id="outlined-basic"
                                        variant="outlined"
                                        onChange={handleChange}
                                        className='input-field' />
                                </Grid>
                                <Grid item xs={12} >
                                    <label>Phone Number</label>
                                    <TextField
                                        name='phoneNumber'
                                        value={formData.phoneNumber}
                                        id="outlined-basic"
                                        variant="outlined"
                                        onChange={handleChange}
                                        className='input-field' />
                                </Grid>
                                <Grid item xs={12}>
                                    <label>Company</label>
                                    <TextField
                                        name='company'
                                        value={formData.company}
                                        id="outlined-basic"
                                        variant="outlined"
                                        onChange={handleChange}
                                        className='input-field' />
                                </Grid>
                                <Grid item xs={12}>
                                    <label>Details/Message</label>
                                    <TextField
                                        name='message'
                                        value={formData.message}
                                        id="outlined-basic"
                                        variant="outlined"
                                        onChange={handleChange}
                                        className='input-field' />
                                </Grid>
                            </Grid>
                        </List>
                    </Box>
                    <Box sx={{ width: '100%', marginTop: '30px' }}>
                        <Button type='submit' className='create-btn'>Submit</Button>
                    </Box>
                </form>
            </div>
        </>
    )
}

export default Advertise
