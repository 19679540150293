import { gql } from "@apollo/client";

export const RETURNS_BEFORE_DELIVERY = gql`
    query {
        returnOrders(
                type: "beforeDelivery",
                pageSize:20,
                currentPage: 1
        )
        {
            order_number
            item_id
            date
            delivery_date
            return_date
            status
            product_name
            product_sku
            product_image
            price
            qty
            sub_total
            currency
        }
    }
`;

export const RETURNS_AFTER_DELIVERY = gql`
    query {
        returnOrders(
                type: "afterDelivery",
                pageSize:20,
                currentPage: 1
        )
        {
            order_number
            item_id
            date
            received_date
            return_date
            status
            product_name
            product_sku
            product_image
            price
            qty
            sub_total
            currency
        }
    }
`;