import React, { useState } from 'react';
import '../Navbar/Navbar.css';
import { Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { Link } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import sellerhublogo from '../../assets/images/seller-hub-logo.svg'

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = '100%';
const navItems = ['HOME', 'REFERRAL FEE', 'FAQ', 'ADVERTISE', 'SELLER TRAINING', 'JEBELZ.COM'];
const paths = ['/', '/referral-fee', '/faq', '/advertise', '/seller-training', 'https://www.jebelz.com'];

export default function Navbar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
    setIsMenuOpen(!isMenuOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <div className='navbar-menu-block'>
        <List className='navbar-menulist'>
          {navItems.map((item, index) => (
            <ListItem key={item} disablePadding>
                <Link to={paths[index]} style={{ textDecoration: 'none', color: 'inherit', width: "100%" }}>
              <ListItemButton sx={{ textAlign: 'left' }}>
                  <ListItemText primary={item} />
              </ListItemButton>
                </Link>
            </ListItem>
          ))}
        </List>
      </div>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }} className="mobile-menu-block">
      {/* <CssBaseline /> */}
      <AppBar component="nav" className='main-top-bar'>
        <Toolbar className='main-top-toolbar'>
          <div className='top-bar'>
          <div>
              <img src={sellerhublogo} alt="" />
            </div>
          <IconButton
          className='nav-toggle-btn'
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              {navItems.map((item, index) => (
                <Link key={item} to={paths[index]} style={{ textDecoration: 'none', color: '#fff' }}>
                <Button sx={{ color: '#fff' }}>{item}</Button>
              </Link>
              ))}
            </Box>
          </div>
          <div className="top-bar-login">
            <div className="form-field-section">
              <div className="register-btn-block">
              <Link to="/seller-login" >
                <Button className='login-btn' variant="contained">Log in</Button>
                </Link>
                <Link to="/register" >
                <Button className='login-btn' variant="contained">Register</Button>
                </Link>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
        
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      {/* <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
       
      </Box> */}
    </Box>
  );
}
