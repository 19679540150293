import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useState } from "react";
import './DashboardTable.scss';
import '../../Pages/Dashboard/Product/Products.scss';
import { Grid, Pagination, Stack, Tab, Tabs, TextField } from "@mui/material";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface Data {
  [key: string]: any;
}

interface Action {
  label: string;
  buttonLabel: string;
  onClick: (row: Data, cont: any) => void;
}

interface DashBoardTableProps {
  headCells: HeadCell[];
  rows: Data[];
  ButtonActions?: Action[];
  onValueChange?: any;
  pageNumber?: any;
  pageType?: any;
  pageChange?: any;
  cont?: any;
}

export default function OrdersTable({ headCells, rows, ButtonActions, onValueChange, pageType, pageNumber, pageChange }: DashBoardTableProps) {
  //handleChangePage = pageChange;
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState<keyof Data>(headCells[0].id);
  const [selected, setSelected] = useState<readonly number[]>([]);
  const [page, setPage] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dense, setDense] = useState(false);
  const [updatedRows, setUpdatedRows] = useState<Data[]>([]);
  const [buttonStatus, setButtonStatus] = useState([]);

  useEffect(() => {
    setUpdatedRows(rows)
  },[rows])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const title = window.location.pathname.split("/");
  const selectedItem = title[title.length - 1].charAt(0).toUpperCase() + title[title.length - 1].slice(1)

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n, index) => index);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, index: number) => {
    const selectedIndex = selected.indexOf(index);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  console.log("selected:", selected)
  console.log("pageType: ",pageType)

  const handleChangePage = (event: unknown, newPage: number) => {
    console.log("pageNumber: ",pageNumber);
    console.log("selected:", localStorage.getItem('selectedMenuItem'))
    //pageNumber[pageType]=newPage;
    console.log("pageChange: ",pageChange);
    pageChange({pageType:pageType});
    setSelectedPage(newPage);
    //console.log("newSelected:", newSelected)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (index: number) => selected.indexOf(index) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  console.log("updatedRows" , updatedRows)
  return (
    <Box sx={{ width: "100%" }} className="commmon-tables desktop-table-block">
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}

          >
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "left" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
                {ButtonActions && ButtonActions.map((action, index) => (
                  <TableCell key={index}>{action.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {updatedRows && rows && rows.map((row, index) => {
                const isItemSelected = isSelected(index);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    // onClick={(event) => handleClick(event, index)} 
                    // role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "left" : "left"}
                      >
                        {
                          headCell.id === 'waybillNumber' && row[headCell.id]!="" ? (
                            <a href={row['waybillFile']} target="_blank">{row[headCell.id]}</a>
                          ) :
                          row[headCell.id] 
                        }
                      </TableCell>
                    ))}
                    {ButtonActions && ButtonActions.map((action, index) => (
                      
                      <TableCell key={index}>
                        {
                            <>
                            <Button variant="contained" color="primary" onClick={() => action.onClick(row,`custom-btn-${index}`)} className={`custom-btn-${index}`}>
                              {action.buttonLabel}
                            </Button>                            
                            </>
                        }
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={headCells.length + (ButtonActions ? ButtonActions.length : 0)} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
