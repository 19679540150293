import React, { createContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
  uri: "https://access.jebelz.com/graphql",
  // uri: "https://jebelz.com/graphql",
  // uri: "https://jebelzm2.ezmartech.com/graphql",
  // uri: "http://jebelzstg.ezmartech.com/graphql",
  cache: new InMemoryCache(),
});

export const CountryContext = createContext({
  store: "",
  setStore: (store: string) => {},
});

const CountryProvider = ({ children}: any) => {
  const [store, setStore] = useState("");
  useEffect(() => {
    const host = window.location.hostname;
    if (host.includes("seller-in")) {
      setStore("sell_in");
    } else if(host.includes("seller-sa")) {
      setStore("sell_sa");
    } else if(host.includes("seller-om") ) {
      setStore("sell_om");
    } else {
      setStore("seller")
    }
  },[])
  return (
    <CountryContext.Provider value={{ store, setStore }}>
      {children}
    </CountryContext.Provider>
  );
};


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
      <CountryProvider>
        <App />
        </CountryProvider>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>
);

reportWebVitals();
