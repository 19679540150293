import { gql } from "@apollo/client";

export const FORGOTPWD_MUTATION = gql`
mutation forgotPwd($email: String!) {
    requestPasswordResetEmail(
        email: $email
      )
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
    mutation resetPassword($email: String!, $resetPasswordToken: String!, $newPassword: String!) {
        resetPassword(
            email: $email,
            resetPasswordToken: $resetPasswordToken,
            newPassword: $newPassword
        )
    }
`;