import { useMutation } from '@apollo/client';
import { Box, Button, Divider, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { settingsMutation } from '../../../Operations/Mutations';
import { toast } from 'react-toastify';
import Toaster from '../../../Components/ToastMessage';
import { useNavigate } from 'react-router-dom';
import { CountryContext } from '../../..';

const EditPickUpDetails = () => {

    const [formDataforPickUp, setFormDataforPickUp] = useState({
        country: '',
        streetAddress: '',
        city: '',
        state: '',
        pinCode: '',
        googleMapLocation: ''
      });

      const loggedInToken = localStorage.getItem("loggedInCust");
      const navigate = useNavigate()

      const {store} = useContext(CountryContext);

      const [updatePickUpData] = useMutation(
        settingsMutation.UPDATE_PICKUP_MUTATION,
        {
          context: {
            headers: {
              Authorization: loggedInToken
                ? `Bearer ${JSON.parse(loggedInToken)}`
                : "",
              Connection: "keep-alive",
              store: store,
            },
          },
        }
      );

      const Countries = ["Oman", "UAE", "India", "Saudi Arabia"];

      const handleCountryChange = (event: SelectChangeEvent) => {
        setFormDataforPickUp({ ...formDataforPickUp, country: event.target.value });
      };

      const handlePickUpFormData = (event: any) => {
        const { name, value } = event.target;
        setFormDataforPickUp({ ...formDataforPickUp, [name]: value });
      };

      const formforAPI = {
        city: formDataforPickUp.city,
        region: formDataforPickUp.state,
        postcode: formDataforPickUp.pinCode,
        vendor_address: formDataforPickUp.streetAddress,
        country: formDataforPickUp.country,
        google_map: formDataforPickUp.googleMapLocation,
      }

      const savePickUpFormData = async () => {
        try{
          const response = await updatePickUpData({
            variables: {
              input : {
                pickupLocation: [formforAPI]
              }
            }    
          });
          console.log("response from APi: ", response)
          toast.success('Successfully changed PickUp location', {
            position: toast.POSITION.TOP_RIGHT
        });
        }catch(error){
          toast.error('Error while changing the PickUp Location', {
            position: toast.POSITION.TOP_RIGHT
        });
        }
      };

      const backToSettings = () => {
        navigate("/dashboard/settings")
      }

  return (
    <Box>
        <Grid container spacing={2} className="change-name pick-up-location">
          <Toaster/>
                <Grid item xs={12} sm={12} md={12} className='settings-header'>
                  <Typography variant='h5'>Pick up location</Typography>
                </Grid>
                <Divider className='divider' />
                <Grid item xs={12} sm={12} md={12} className='title text-title'>
                  <Typography variant='h6'>Use the form below to change the Pick up location for your Jebelz account</Typography>
                </Grid>
                <Grid container spacing={2} xs={12} sm={12} md={12} lg={9} className='form-details'>
                  <Grid item xs={12} sm={12} md={12} className='name'>
                    <label>Country</label>
                    <FormControl className='select-form'>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formDataforPickUp.country}
                        label="Yearly"
                        onChange={handleCountryChange}
                      >
                        {Countries.map((item, index) => (
                          <MenuItem key={index} value={item} className='menuitem-select'>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>First Name</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='firstName'
                      value={formDataforPickUp.firstName}
                      onChange={handlePickUpFormData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>Last Name:</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='lastName'
                      value={formDataforPickUp.lastName}
                      onChange={handlePickUpFormData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>Mobile number:</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='mobileNumber'
                      value={formDataforPickUp.mobileNumber}
                      onChange={handlePickUpFormData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>Email</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='email'
                      value={formDataforPickUp.email}
                      onChange={handlePickUpFormData}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12} className='name'>
                    <label>Street Address:</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='streetAddress'
                      value={formDataforPickUp.streetAddress}
                      onChange={handlePickUpFormData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>Town/City</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='city'
                      value={formDataforPickUp.city}
                      onChange={handlePickUpFormData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>Region</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='state'
                      value={formDataforPickUp.state}
                      onChange={handlePickUpFormData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>Post code</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='pinCode'
                      value={formDataforPickUp.pinCode}
                      onChange={handlePickUpFormData}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className='name'>
                    <label>Google Map Location</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='googleMapLocation'
                      value={formDataforPickUp.googleMapLocation}
                      onChange={handlePickUpFormData}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className='btn-grid'>
                  <Button className='btn btn-back' onClick={backToSettings}>
                    <Typography variant='h6'>Back</Typography>
                  </Button>
                  <Button className='btn' onClick={savePickUpFormData}>
                    <Typography variant='h6'>Save Changes</Typography>
                  </Button>
                </Grid>
              </Grid>
    </Box>
  )
}

export default EditPickUpDetails