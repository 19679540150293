import "./PasswordReset.css"

import * as mutations from "../../../Operations/Mutations";
import { Divider, Typography, Grid, ListItem, TextField, Button, Box, List, Checkbox, Alert, AlertTitle } from '@mui/material'
import { CountryContext } from '../../..';

import { Link, Navigate } from 'react-router-dom';
import '../../../../src/mainseller.scss'
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { split, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toaster from '../../../Components/ToastMessage';

interface FormDataInterface {
    loginInformation: [{
        email: string,
        password: string;
        validated: boolean;
    }];
}
const PasswordReset = () => {
    const {store} = useContext(CountryContext);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [login, setLogin] = useState(false);
    const [userInput, setUserInput] = useState(false);
    const [enableSubmit, updateEnableSubmit] = useState({status: 'disabled'});
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [resError, setResError] = useState("");
    const navigate = useNavigate();
    
    const initialFormData: FormDataInterface = {
        loginInformation: [{
            email: '',
            password: '',
            validated: false
        }]
    }
    const [formData, setFormData] = useState<FormDataInterface>(initialFormData);
    let [passwordResetRequest] = useMutation(
            mutations.forgotPwdMutation.RESET_PASSWORD_MUTATION,
            {
                context: {
                    headers: {
                        Connection: "keep-alive",
                        store: store
                    },
                },
                onCompleted: (response) => {
                    console.log(response);
                },
                onError: (error) => {
                    console.log("error 66: ",error.message);
                    console.log("error 66: ",error.name);
                    console.log("error 66: ",error.networkError);
                    console.log("error 66: ",error.protocolErrors);
                    console.log("error 66: ",error.stack);
                }
            },
        );
    
    const isStrongPassword = (password: any) => {
        const strongPasswordRegex =
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/;
        const response = strongPasswordRegex.test(password);
        formData.loginInformation[0].validated = response;
        return response;
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, section: keyof FormDataInterface) => {
        const { name, value } = e.target;
        setFormData((prevState: FormDataInterface) => ({
            ...prevState,
            [section]: [{
                ...prevState[section][0],
                [name]: value
            }]
        }));
    };
    const matchPassword = (e: any) => {
        if(formData.loginInformation[0].password===e.target.value) {
            setPasswordMatch(true);
        }
        else {
            setPasswordMatch(false);
            formData.loginInformation[0].validated = false;
        }
    }
    const handleSubmit = async (e: any) => {
        e.preventDefault();
        console.log(formData.loginInformation);
        try{
            
            if(formData.loginInformation[0].validated) {
                console.log(window.location);
                const token = window.location.search.split("?token=")
                console.log("validated",token[1]);
                const response = await passwordResetRequest({
                    variables: {
                        email: formData.loginInformation[0].email,    
                        resetPasswordToken: token[1],
                        newPassword: formData.loginInformation[0].password
                    }
                });
                //console.log(response);
                if(response?.data) {
                    setShowErrorAlert(false);
                    console.log(response);
                    toast.success('Password updated successfully!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setTimeout(() => {
                        navigate("/seller-login");
                    }, 2000);
                }
                
            }
            else {
                console.log("not validated");
                setResError("Invalid credentials");
                toast.error('Invalid credentials', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setShowErrorAlert(true);
            }
        }
        catch(error) {
            console.log(error);
            setResError("Invalid credentials");
            toast.error('Invalid credentials', {
                position: toast.POSITION.TOP_RIGHT
            });
            setShowErrorAlert(true);
        }
    }
    return (
        <div className='login-main' style={{minHeight:"717px"}}>
            <div className='seller-acc-main'>
            {<Toaster/>}
            <Typography variant='h1'>Update Password</Typography>
            {/*showErrorAlert && (
                <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {resError}
                </Alert>
            )*/}
            <form onSubmit={handleSubmit}>
                <Box sx={{
                    border: "1px solid #DEDCDC",
                    backgroundColor: "#FFFFFF"
                }} className="main-box">
                    <List>
                        <ListItem>
                            <Typography className='title'>
                                Update Password
                            </Typography>
                        </ListItem>
                        <Divider />
                        <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                            <Grid item xs={12} md={12} lg={12}>
                                <label>Email</label>
                                <TextField
                                    name='email'
                                    variant="outlined"
                                    className='input-field'
                                    type='text'
                                    onChange={(e) => handleChange(e, 'loginInformation')}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <label>Password</label>
                                <TextField
                                    name='password'
                                    variant="outlined"
                                    className='input-field'
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(e) => handleChange(e, 'loginInformation')}
                                />
                                <Typography className='password-msg'>
                                    Password Strength: {formData.loginInformation[0].password.length === 0 ? (
                                        <span>No Password</span>
                                    ) : isStrongPassword(formData.loginInformation[0].password) ? (
                                        <span style={{ color: "green" }}>
                                            Strong password
                                        </span>
                                    ) : (
                                        <span style={{ color: "red" }}>
                                            Weak password
                                        </span>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <label>Confirm Password</label>
                                <TextField
                                    name='confirm_password'
                                    variant="outlined"
                                    className='input-field'
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={matchPassword}
                                />
                                <Typography className='password-msg'>
                                    { 
                                    (!isStrongPassword(formData.loginInformation[0].password)) ?
                                        <span style={{ color: "red" }}>
                                            Weak password
                                        </span>
                                    : (isStrongPassword(formData.loginInformation[0].password) && passwordMatch) ?
                                        <span style={{ color: "green" }}>
                                            Password Matched
                                        </span>
                                    :
                                        <span style={{ color: "red" }}>
                                            Password not matched
                                        </span>
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ maxWidth: '100% !important' }}>
                                <Box sx={{ width: '100%', marginTop: '30px' }}>
                                    <Button className='create-btn' type='submit'>
                                        Update Password
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </List>
                </Box>
            </form>
            </div>
        </div>
    )
}

export default PasswordReset;