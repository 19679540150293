import React from 'react'
import "./Footer.css"
import { Box, Grid, Link, Typography } from '@mui/material'

const Footer = () => {
  return (
    <>
    <div className='footer-wrap'>
    <div className='footer-top'>
        
        <Box sx={{width: '100%'}}>
        <Grid container spacing={2} sx={{display: 'flex', justifyContent: 'space-between', padding: '15px 0px'}}>
          <Grid item xs={12} md={6} lg={6} className='foooter-link' sx={{display: 'flex', alignItems: 'center'}}>
             <Link href="#">Terms and Conditions</Link>
             <Link href="#">Privacy Policy Help</Link>
             <Link href="#">Help & Support</Link>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className='support-text'>
            <Typography variant='h6'>Still have questions? Visit our <Link href="#">Support Center for sellers</Link></Typography>
          </Grid>
         
        </Grid>
        
        </Box>
       
    </div>
    <div className='footer-bottom'>
        <p>© 2018-2024, Jebelz - All Rights Reserved</p>
    </div>
    </div>
    </>
  )
}

export default Footer