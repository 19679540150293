import { Box, Button, CircularProgress, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Tab, Tabs, TextField, Typography, styled } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import './Products.scss';
import ImageIcon from '@mui/icons-material/Image';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import DashBoardTable from '../../../Components/Dashboard_Table/DashboardTable';
import MobileTable from '../../../Components/Mobile_Table/MobileTable';
import MobileMenuBar from '../../../Components/Mobile_Menubar/MobileMenubar';
import { useMutation, useQuery } from '@apollo/client';
import { productPageQuery } from '../../../Operations/Queries';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { productsMutation } from '../../../Operations/Mutations';
import CancelIcon from '@mui/icons-material/Cancel';
import Toaster from '../../../Components/ToastMessage';
import { toast } from 'react-toastify';
import { CountryContext } from '../../..';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { isNumber } from 'util';
import { settingsPageQuery } from '../../../Operations/Queries';
import { useNavigate } from 'react-router-dom';
import * as mutations from "../../../Operations/Mutations";
import { getGraphQLErrorsFromResult, graphQLResultHasError } from '@apollo/client/utilities';
import { error } from 'console';
import { ApolloError, GraphQLErrors } from '@apollo/client/errors';
import { onError } from "apollo-link-error";
//import { ServerResponse } from 'http';
//import { onError } from "@apollo/client/link/error";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ProductImageInterface {
  name: string;
  size: number;
  progress: number;
}

interface searchProduct {
  sku: string;
  status: string;
  name: string;
  price: number;
  image: string;
  qty: number;
  weight: number;
  ean: string;
  image_gallery: { url: string }[];
}

interface Data {
  [key: string]: any;
}

interface UploadedFile {
  name: string;
  base64String: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   height: 10,
//   borderRadius: 5,
//   [`&.${linearProgressClasses.colorPrimary}`]: {
//     backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//   },
//   [`& .${linearProgressClasses.bar}`]: {
//     borderRadius: 5,
//     backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
//   },
// }));

const Product = () => {
  const [value, setValue] = useState(0);
  const [selectedMenuItem, setSelectedMenuItem] = useState('Create New Product');
  const [uploadedProductImages, setUploadedProductImages] = useState<ProductImageInterface[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchProductTerm, setSearchProductTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<searchProduct | null>(null);
  const [updatedPrice, setUpdatedPrice] = useState(selectedProduct?.price || '');
  const [updatedQuantity, setUpdatedQuantity] = useState(selectedProduct?.qty || '');
  const [searchResultModalShow, setSearchResultModalShow] = useState(true)
  const [editPriceTableData, setEditPriceTableData] = useState<any[]>([]);
  const [updatedRows, setUpdatedRows] = useState<Data[]>([]);
  const [totalPage, setTotalPage] = useState<any>();
  const [selectedPage, setSelectedPage] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [bulkProductVariables, setBulkProductVariables] = useState({
    pageSize: 300,
    currentPage: 1,
    type: "",
    downloadAll: "true"
  });
  const [dataforNewProduct, setDataForNewProduct] = useState({
    name: "",
    sku: "",
    price: "",
    qty: "",
    weight: "",
    ean: "",
    shortDescription: "",
    description: "",
    images: [
      {
        name: "",
        base64_encoded_file: ""
      },
      {
        name: "",
        base64_encoded_file: ""
      }
    ]
  })
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [bulkUploadStr, setBulkUploadStr] = useState("")
  const [bulkUpdateStr, setBulkUpdateStr] = useState("")


  const loggedInToken = localStorage.getItem("loggedInCust");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const InputRefForBulkUpdate = useRef<HTMLInputElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { store } = useContext(CountryContext);

  const { data: searchProductData } = useQuery(productPageQuery.SEARCH_PRODUCT, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
      onerror(error: any) {
        console.log("error 00: ",error);
      }
    },
    variables: {
      searchByName: searchTerm,
      pageSize: 20,
      currentPage: 1
    }
  });
  
  const { data: editProductData, refetch: editProductDataRefetch, error: editProductDataError } = useQuery(productPageQuery.EDIT_PRICE_QUANTITY_LIST, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
      onerror(error: any) {
        console.log("error 10: ",error);
      }
    },
    variables: {
      pageSize: 10,
      searchProductTerm: searchProductTerm,
      currentPage: currentPage,
      type: "seller_products"
    }
  });
  console.log("editProductsdata:", editProductData)
  const EditPriceProductData = editProductData?.sellerProductsList[0]?.items

  const errorEditProductData = editProductDataError?.message;
  console.log("error:", errorEditProductData)
  const [revokeCustomertoken] = useMutation(mutations.logoutMutation.REVOKE_CUSTOMER_TOKEN,
    {
       context: {
          headers: {
             Authorization: loggedInToken
                ? `Bearer ${JSON.parse(loggedInToken)}`
                : "",
             Connection: "keep-alive",
             store: store,
          },
       },
    });
  const handleLogOut = async () => {
      console.log("logout clicked")
      try {
          const response = await revokeCustomertoken();
          console.log("response: ", response.data.revokeCustomerToken.result);
          localStorage.removeItem('selectedMenuItem');
          navigate("/")
      } catch (error) {
          console.error('Error revoking token:', error);
          localStorage.removeItem('selectedMenuItem');
          navigate("/")
      }
  }
  const { data: SettingsMainData, error: settingsError, loading: settingsLoading } = useQuery(settingsPageQuery.SETTINGS_MAINPAGE, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
      onerror(error: any) {
        console.log("error 11: ",error);
        handleLogOut();
      }
    },
  });
  if(errorEditProductData=="Response not successful: Received status code 401") {
    const settingsData = SettingsMainData?.sellerData;
    console.log("SettingData:", settingsData)
    if(settingsData==undefined) handleLogOut();
  }

  const [addProduct, { loading: addProductLoading, error: addProductError }] = useMutation(
    productsMutation.ADD_PRODUCT,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const [updateProduct, { loading: updateProductDataLoading, error: updateProductDataError }] = useMutation(
    productsMutation.UPDATE_PRODUCT,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const [createProduct, { loading: createProductLoading, error: createProductError }] = useMutation(
    productsMutation.CREATE_PRODUCT,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
      onError: (error: ApolloError,success) => {
        /*const link = onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              )
            );
          if (networkError) console.log(`[Network error]: ${networkError}`);
        });*/
        console.log("createProductError 1: ",success);
        //const newworkError = ((error?.networkError: ServerResponse) => () => {
          //console.log("createProductError 22: ",ServerResponse);
        //})
        console.log("createProductError 11: ",error.extraInfo);
        console.log("createProductError 101: ",error.networkError);
      },
      onCompleted: (data: any) => {
        console.log("createProductError 2 completed: ",data);
      }
    },
  );

  

  const [bulkUpload] = useMutation(
    productsMutation.BULK_UPLOAD,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const [bulkUpdate] = useMutation(
    productsMutation.BULK_UPDATE,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  useEffect(() => {
    if (selectedProduct) {
      setUpdatedPrice(selectedProduct?.price);
      setUpdatedQuantity(selectedProduct?.qty);
    }
  }, [selectedProduct]);

  useEffect(() => {
    const transformed = EditPriceProductData?.map((order: any, index: any) => ({
      id: index,
      sku: order?.sku,
      itemName: order?.name,
      image: order?.image,
      itemPrice: order?.price,
      quantity: order?.qty,
      status: order?.status,
    }));
    setEditPriceTableData(transformed)
  }, [EditPriceProductData]);

  useEffect(() => {
    const pageInfo = editProductData?.sellerProductsList[0]?.pageInfo;
    //console.log(pageInfo);
    const totalPagesA = Math.round(parseInt(pageInfo?.total_pages) / 10 );
    //console.log("totalPagesA",totalPagesA,typeof(totalPagesA));
    if(typeof(totalPagesA)=="number") setTotalPage(totalPagesA);
  });

  console.log("tranformed data:", editPriceTableData)

  const headCellsForEditPrice = [
    { id: 'sku', numeric: false, disablePadding: false, label: 'SKU' },
    { id: 'itemName', numeric: false, disablePadding: false, label: 'Item Name' },
    { id: 'image', numeric: false, disablePadding: false, label: 'Image' },
    { id: 'itemPrice', numeric: true, disablePadding: false, label: 'Item Price (AED)' },
    { id: 'quantity', numeric: true, disablePadding: false, label: 'Quantity' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  ];
  const rowsForEditPrice = [
    { id: 1, itemName: 'Segafredo - Nespresso Compatible & Compostabl', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1, update: 'Update' },
    { id: 2, itemName: 'Segafredo - Zanetti Express', itemPrice: 'AED 816.00', quantity: 1, itemTotal: 1, update: 'Update' },
    { id: 3, itemName: 'Segafredo - Capsule MyEspresso', itemPrice: 'AED 1053.00', quantity: 1, itemTotal: 1, update: 'Update' },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const additionalActionsForeditPrice = [
    {
      label: 'Update',
      buttonLabel: 'Update',
      onClick: async (row: any) => {
        const index = updatedRows.findIndex((updatedRow) => updatedRow.id === row.id);
        if (index !== -1) {
          const updatedRowAfterClick = {
            ...row,
            itemPrice: updatedRows[index].itemPrice,
            quantity: updatedRows[index].quantity
          };
          // console.log('Updated row:', updatedRowAfterClick);
          const priceForUpdateApi = updatedRowAfterClick.itemPrice;
          const quantityForUpdateApi = updatedRowAfterClick.quantity;
          const skuForUpdateApi = updatedRowAfterClick.sku;
          // console.log("sku:", skuForUpdateApi, "qty:", quantityForUpdateApi, "price:", priceForUpdateApi)
          try {
            const response = await updateProduct({
              variables: {
                input: {
                  sku: skuForUpdateApi,
                  price: priceForUpdateApi,
                  qty: quantityForUpdateApi,
                  type: "update"
                }
              }
            });
            const data = response.data;
            console.log("data after saving:", data)
            toast.success('Successfully updated', {
              position: toast.POSITION.TOP_RIGHT
            });
            editProductDataRefetch();
          } catch (error) {
            console.error('Error saving password 22:', error);
            toast.error('Error updating', {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        }
      }
    },
  ];

  const menuItems = [
    'Create New Product',
    'Add a Product',
    'Bulk Upload',
    'Easy Edit Price & Quantity',
    'Bulk Price & Quantity Update',
  ];

  const handleMenuItem = (itemName: any) => {
    setSelectedMenuItem(itemName);
  };
  console.log("selectedMenuItem:", selectedMenuItem)

  const handleProductFileUpload = async (event: any) => {
    const files = event.target.files;
    const newFiles: UploadedFile[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
      try {
        const base64String = await readFileAsBase64(file);
        const finalBase64String = `data:image/${fileExtension};base64,${base64String}`;
        console.log("finalBase64", finalBase64String)
        newFiles.push({ name: file.name, base64String: finalBase64String });
      } catch (error) {
        console.error('Error reading file:', error);
      }
    }
    // Update state with the new array of files
    setUploadedFiles(prevUploadedFiles => [...prevUploadedFiles, ...newFiles]);
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target?.result) {
          const base64String = btoa(new Uint8Array(event.target.result as ArrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
          resolve(base64String);
        } else {
          reject(new Error('Error reading file'));
        }
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleSampleDownload = async () => {
    console.log("Sample Download Icon clicked.")
    try {
      const response = await fetch('/csv_files/Bulk_Upload.csv');
      const csvData = await response.text();
      console.log("data from csv file:", csvData)

      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'Bulk_Upload.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading CSV file:', error);
    }
  }
  const { data: bullProductData, refetch: refetchBulkProductData, error: editBullProductData } = useQuery(productPageQuery.EDIT_PRICE_QUANTITY_LIST, {
    variables: bulkProductVariables ,
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
      onerror(error: any) {
        console.log("error 10: ",error);
      }
    }
  });

  const { data: bulkDonwload, refetch: refetchBulkDownload, error: bulkDonwloadError } = useQuery(productPageQuery.BULK_PRODUCT_DOWNLOAD, {
    variables: bulkProductVariables ,
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
      onerror(error: any) {
        console.log("error 10: ",error);
      }
    }
  });
  const handleDownloadAllProducts = async () => {
    console.log("handleDownloadAllProducts .......................");
    setBulkProductVariables({
      pageSize: 300,
      currentPage: 1,
      downloadAll: "true",
      type: "seller_products"
    });

    refetchBulkDownload().then((res: any) => {
      try {
        /*console.log("sellerProductsList:", res?.data?.sellerProductsList[0]?.items)
        const items = res?.data?.sellerProductsList[0]?.items;
        const csvData = [];
        //sku,name,weight,price,qty,ean,images
        csvData.push(`sku,name,price,qty`);
        items.forEach((data: any) => {
          csvData.push(`${data?.sku.replaceAll(/\,/g,'-')},${data?.name.replaceAll(/\,/g,'-')},${data?.price},${data?.qty}`);
        });
        //const csvItems = JSON.stringify(csvData, null, "\t").replaceAll("],\n\t\"", "],\n\n\t\"");
        // const csvItems = JSON.stringify(csvData, null, "\t").replaceAll(/[\[ \]|\"]|\n\t\"|\\n|\t]/g, "");
        const csvItems = JSON.stringify(csvData, null, "\t").replaceAll(/\[|\]\t\n|\\n|\"|]|\t|\\t/g, "");

        //const response = await fetch('/csv_files/Bulk_Upload.csv');
        //const csvData = await response.text();
        console.log("data from csv file:", csvItems)
        const csvItemsNew = csvItems.replaceAll(/\[|\]\t\n|\\n|\"|]|\r\t|\\t/g, "");
        
        const blob = new Blob([csvItemsNew].splice(0, 1), { type: 'text/csv' });
        const url = URL.createObjectURL(blob);*/

        //const a = document.createElement('a');
        console.log("download_url: ",res?.data?.sellerProductDownload[0]);
        window.open(res?.data?.sellerProductDownload[0]?.download_url);
        //a.download = 'Bulk_Upload.csv';
        //document.body.appendChild(a);
        //a.click();
        //document.body.removeChild(a);

        //console.log("editProductsdata:", csvItems)
        //const response = await fetch('/csv_files/Bulk_Update.csv');
        // const csvDataNew = await response.text();
        // console.log("data from csv file:", csvData)
  
        /*const blob = new Blob([csvItems], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
  
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Bulk_Update.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);*/
      } catch (error) {
        console.error('Error downloading CSV file:', error);
      }
    })
    .catch((err: any) => {
      console.log(err);
    });
    
    console.log("Download All products Icon clicked.")
    
  }
  useEffect(() => {
    console.log("productsData: ",bullProductData);
  }, [bullProductData]);

  const handleProductSelect = (product: searchProduct) => {
    setSelectedProduct(product);
    setSearchResultModalShow(false);
  };
  console.log("selected searched product", selectedProduct)

  //add a product price update
  const handlePriceChange = (event: any) => {
    setUpdatedPrice(event.target.value);
  };

  //add a product quantity update
  const handleQuantityChange = (event: any) => {
    setUpdatedQuantity(event.target.value);
  };
  console.log("Updated qty:", updatedQuantity, "updated price:", updatedPrice)

  const finalSku = selectedProduct?.sku;
  const finalPrice = updatedPrice;
  const finalQty = updatedQuantity;

  const handleAddProduct = async (e: any) => {
    e.preventDefault();
    try {
      const response = await addProduct({
        variables: {
          input: {
            sku: finalSku,
            price: finalPrice,
            qty: finalQty,
            type: "add",
          },
        },
      });
  
      toast.success("Product added successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error: any) {
      console.error("Error saving product:", error);
  
      // Check if the error is a network error
      if (error?.networkError) {
        // Display network error message
        const networkErrorMessage =
          error.networkError.result?.errors?.[0]?.message ||
          error.networkError.message ||
          'Network error: Failed to fetch';
        toast.error(`${networkErrorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } 
      // Check if there are GraphQL errors
      else if (error?.graphQLErrors && error.graphQLErrors.length > 0) {
        // Display the first GraphQL error message
        const errorMessage =
          error.graphQLErrors[0]?.message || "Unknown GraphQL error";
        toast.error(`${errorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } 
      // Fallback for any other errors
      else {
        toast.error("An unknown error occurred", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  
  console.log("searchProductData:", searchProductData)

  const handleEditPriceQuantityChange = (value: any) => {
    setUpdatedRows(value);
    console.log("dsdf")
  };

  console.log("Row values from parent component", updatedRows)

  const handleDataForNewProduct = (e: any, fieldName: any) => {
    const { value } = e.target;
    setDataForNewProduct(prevData => ({
      ...prevData,
      [fieldName]: value
    }));
  }

  const submitCreateProductData = async (e: any) => {
    console.log("dataforNewProduct", dataforNewProduct)
    e.preventDefault();
    try {
      const response = await createProduct({
        variables: {
          input: {
            name: dataforNewProduct.name,
            sku: dataforNewProduct.sku,
            price: dataforNewProduct.price,
            qty: dataforNewProduct.qty,
            weight: dataforNewProduct.weight,
            ean: dataforNewProduct.ean,
            shortDescription: dataforNewProduct.shortDescription,
            description: dataforNewProduct.description,
            images: uploadedFiles?.map(image => ({
              name: image.name,
              base64_encoded_file: image.base64String
            }))
          }
        }
      }).catch((error) => {
        console.log("createProductError 33 error: ",error)
      });
      createProductError?.graphQLErrors.map(({ message }, i) => (
        console.log("createProductError 33 error:  ",i,message)
      ))
      const link = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `createProductError [GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      });
      //console.log("createProductError 44 data: ",response)
      /*console.log("data of new product:", data)
      toast.success('Successfully added new product', {
        position: toast.POSITION.TOP_RIGHT
      });*/

      /*setDataForNewProduct({
        name: "",
        sku: "",
        price: "",
        qty: "",
        weight: "",
        ean: "",
        shortDescription: "",
        description: "",
        images: []
      })
      setUploadedFiles([])*/
    } catch (error) {
      console.error('Error saving password 11:',error, createProductError?.cause);
      if (editProductDataError && errorEditProductData === "Seller is not loggedin") {
        toast.error(`${errorEditProductData}`, {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        toast.error('Error adding new product', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  const handleCheckboxChange = (index: any) => {
    console.log("Checkbox checked for index:", index);
    if (index === selectedFileIndex) {
      return;
    }
    setSelectedFileIndex(index);
  };

  const removeProductImage = (indexToRemove: any) => {
    console.log("indextoRemove:", indexToRemove)
    setUploadedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
  };

  const errorUpdateProductData = updateProductDataError?.message;
  console.log("uploaded images files:", uploadedFiles)

  const handleBulkSheetUpload = () => {
    fileInputRef.current?.click(); // This triggers the click event on the hidden file input
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const allowedExtensions = ['csv'];
    const fileExtension = file?.name.split('.').pop()?.toLowerCase() || '';

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error('Please select a CSV file', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const base64String = btoa(new Uint8Array(reader.result as ArrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        const finalBase64String = `data:application/${fileExtension};base64,${base64String}`;
        console.log("finalBase64 of bulkSheet", finalBase64String);
        setBulkUploadStr(finalBase64String);
        toast.success('Uploaded Bulk upload sheet ! Remember to update product', {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        console.error('Error reading file');
        toast.error('Error uploading Bulk upload sheet !', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    console.log("BulkUploadState:", bulkUploadStr)
  }, [bulkUploadStr])

  const uploadBulkSheet = async (e: any) => {
    e.preventDefault();
    try {
      const response = await bulkUpload({
        variables: {
          input: {
            type: "add",
            file: bulkUploadStr,
          }
        }
      });
      const data = response.data;
      console.log("data after saving:", data)
      toast.success('Bulk Upload sheet saved successfully !', {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch (error: any) {
      console.error("Error saving product:", error);
  
      // Check if the error is a network error
      if (error?.networkError) {
        // Display network error message
        const networkErrorMessage =
          error.networkError.result?.errors?.[0]?.message ||
          error.networkError.message ||
          'Network error: Failed to fetch';
        toast.error(`${networkErrorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } 
      // Check if there are GraphQL errors
      else if (error?.graphQLErrors && error.graphQLErrors.length > 0) {
        // Display the first GraphQL error message
        const errorMessage =
          error.graphQLErrors[0]?.message || "Unknown GraphQL error";
        toast.error(`${errorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } 
      // Fallback for any other errors
      else {
        toast.error("An unknown error occurred", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }

  const handleBulkSheetUpdate = () => {
    InputRefForBulkUpdate.current?.click(); // This triggers the click event on the hidden file input
  };

  const handleFileForBulkUpdate = (event: any) => {
    const file = event.target.files[0];
    const allowedExtensions = ['csv'];
    const fileExtension = file?.name.split('.').pop()?.toLowerCase() || '';

    if (!allowedExtensions.includes(fileExtension)) {
      toast.error('Please select a CSV file', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        const base64String = btoa(new Uint8Array(reader.result as ArrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
        const finalBase64String = `data:application/${fileExtension};base64,${base64String}`;
        console.log("finalBase64 of bulkSheet", finalBase64String);
        setBulkUpdateStr(finalBase64String);
        toast.info('Uploaded Bulk update sheet ! Remember to update product', {
          position: toast.POSITION.TOP_RIGHT
        });
      } else {
        console.error('Error reading file');
        toast.error('Error uploading Bulk update sheet !', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      if (InputRefForBulkUpdate.current) {
        InputRefForBulkUpdate.current.value = '';
      }
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    console.log("BulkUploadState:", bulkUpdateStr)
  }, [bulkUpdateStr])

  const uploadBulkUpdateSheet = async (e: any) => {
    e.preventDefault();
    try {
      const response = await bulkUpdate({
        variables: {
          input: {
            type: "update",
            file: bulkUpdateStr
          }
        }
      });
      const data = response.data;
      console.log("data after saving:", data)
      toast.success('Bulk update sheet saved successfully !', {
        position: toast.POSITION.TOP_RIGHT
      });
      editProductDataRefetch();
    }  catch (error: any) {
      console.error("Error saving product:", error);
  
      // Check if the error is a network error
      if (error?.networkError) {
        // Display network error message
        const networkErrorMessage =
          error.networkError.result?.errors?.[0]?.message ||
          error.networkError.message ||
          'Network error: Failed to fetch';
        toast.error(`${networkErrorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } 
      // Check if there are GraphQL errors
      else if (error?.graphQLErrors && error.graphQLErrors.length > 0) {
        // Display the first GraphQL error message
        const errorMessage =
          error.graphQLErrors[0]?.message || "Unknown GraphQL error";
        toast.error(`${errorMessage}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } 
      // Fallback for any other errors
      else {
        toast.error("An unknown error occurred", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }
  const handleSetSearchTerms = (e: any) => {
    console.log("value: ",e.target);
    setSearchProductTerm(e.target.value);
  }
  const handleSearchProduct = (e: any) => {
    
    editProductDataRefetch();
  };
  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
    setSelectedPage(value);
  };

  return (

    <div className="product-form-section">
      <MobileMenuBar menuItems={menuItems} onItemClick={handleMenuItem} selectedMenuItem={selectedMenuItem} />
      <Box sx={{ width: '100%' }} className="orders-tabs mobile__view__none">
        <Grid item xs={12} sm={12} md={7}>
          <Box className='order-tabs-head' sx={{ borderBottom: 1, borderColor: 'divider', }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='order-tabs-head'>
              <Tab label="Create New Product" {...a11yProps(0)} className='tabs-title' />
              <Tab label="Add a Product" {...a11yProps(1)} className='tabs-title' />
              <Tab label="Bulk Upload" {...a11yProps(2)} className='tabs-title' />
              <Tab label="Easy Edit Price & Quantity" {...a11yProps(3)} className='tabs-title' />
              <Tab label="Bulk Price & Quantity Update" {...a11yProps(4)} className='tabs-title' />
            </Tabs>
          </Box>
        </Grid>
        <CustomTabPanel value={value} index={0} >
          <Box className='add-product-tab '>
            {<Toaster />}
            <div className='main-body'>
              <Grid className='company-upper' container spacing={2} >
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label >Product Name</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='productName'
                      placeholder='Product Name'
                      value={dataforNewProduct.name}
                      onChange={(e: any) => handleDataForNewProduct(e, 'name')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label >SKU</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='sku'
                      placeholder='SKU'
                      value={dataforNewProduct.sku}
                      onChange={(e: any) => handleDataForNewProduct(e, 'sku')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label >Selling Price</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='sellingPrice'
                      placeholder='Selling Price'
                      value={dataforNewProduct.price}
                      onChange={(e: any) => handleDataForNewProduct(e, 'price')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label >Quantity</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='quantity'
                      placeholder='Quantity'
                      value={dataforNewProduct.qty}
                      onChange={(e: any) => handleDataForNewProduct(e, 'qty')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label className='unastrik'>Weight</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='weight'
                      placeholder='Weight'
                      value={dataforNewProduct.weight}
                      onChange={(e: any) => handleDataForNewProduct(e, 'weight')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label >EAN/UPC (Bar code No. Of product)</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='barcodeNumber'
                      placeholder='EAN/UPC (Bar code No. Of product)'
                      value={dataforNewProduct.ean}
                      onChange={(e: any) => handleDataForNewProduct(e, 'ean')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label className='unastrik'>Short Description </label>
                    <TextField
                      placeholder="Short Description"
                      required
                      fullWidth
                      multiline
                      rows={4}
                      maxRows={10}
                      value={dataforNewProduct.shortDescription}
                      onChange={(e: any) => handleDataForNewProduct(e, 'shortDescription')}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label className='unastrik'> Description </label>
                    <TextField
                      placeholder="Description"
                      required
                      fullWidth
                      multiline
                      rows={4}
                      maxRows={10}
                      value={dataforNewProduct.description}
                      onChange={(e: any) => handleDataForNewProduct(e, 'description')}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                <Grid item xs={12} md={12} lg={12}>
                  <h4 className='block-title'>Upload Images</h4>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='upload-img'>
                    <input
                      type="file"
                      id="uploadfile"
                      multiple
                      onChange={handleProductFileUpload} />
                    <label htmlFor="uploadfile" className="btn-1">
                      <CloudUploadIcon />
                      upload file</label>
                  </Box>
                </Grid>
                {uploadedFiles && uploadedFiles.length > 0 && (
                  <Grid item xs={12} md={6} lg={6}>
                    <h4>Uploaded Images</h4>
                    {/* <div className="status-upload-img">
                    <div className='status-upload-block'>
                      <div className='upload-img-item'>
                        <img src={require("../../../assets/images/upload-img.png")} alt="upload-img" />
                      </div>
                      <div className='progress-block'>
                        <div className='progress-block-label'>
                          <div className="img-name">Photo.png </div>
                          <div className="img-size">7.5 mb</div>
                          <div className="img-close">X</div>
                        </div>
                        <div className='linear-progress-bar'>
                          <BorderLinearProgress variant="determinate" value={80} />
                        </div>
                        <div className='process-block-label'>
                          <div className="process-done">37% done</div>
                          <div className="process-time">90KB/sec</div>
                        </div>
                      </div>
                    </div>
                    <div className="select-items">
                      <FormControlLabel required control={<Checkbox />} label="Select Main Photo" />
                    </div>
                    <hr />
                  </div> */}
                    {uploadedFiles?.map((file, index) => (
                      <div className="status-upload-img" key={index}>
                        <div className="status-upload-block">
                          <div className="upload-img-item">
                            <img src={file.base64String} alt={`Uploaded file ${index}`} style={{ width: "50px", height: "50px" }} />
                          </div>
                          <div className="progress-block">
                            <div className="progress-block-label">
                              <div className="img-name">{file.name}</div>
                              <div className="img-close" ><CancelIcon onClick={() => removeProductImage(index)} /></div>
                            </div>
                          </div>
                        </div>
                        <div className="select-items">
                          <FormControlLabel
                            required
                            control={
                              <Checkbox
                                checked={selectedFileIndex === index}
                                onChange={() => handleCheckboxChange(index)}
                              />
                            }
                            label="Select Main Photo"
                          />
                        </div>
                        <hr />
                      </div>
                    ))}
                  </Grid>
                )}
              </Grid>
            </div>
            <div className='btn-group'>
              <Button className='save-btn' onClick={submitCreateProductData}><Typography className='btn-text'>Save Product</Typography></Button>
              <Button className='cancel-btn'><Typography className='btn-text'>Cancel</Typography></Button>
            </div>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box className='add-product-jebelzId'>
            {<Toaster />}
            <div className="main">
              <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap product-search-box'>
                    <label className='unastrik'>Search by product</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='jebelzId'
                      placeholder='Search by product'
                      value={searchTerm}
                      onChange={(e: any) => setSearchTerm(e.target.value)}
                      onFocus={() => setSearchResultModalShow(true)}
                    // onFocus={() => {
                    //   if (searchTerm.trim() === "") {
                    //     setSearchResultModalShow(false);
                    //   } else {
                    //     setSearchResultModalShow(true);
                    //   }
                    // }}
                    />
                    {searchResultModalShow && searchProductData && searchProductData?.sellerProducts && (
                      <div className="product-search-result">
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                          {searchProductData?.sellerProducts?.map((product: any, index: number) => (
                            <ListItem onClick={() => handleProductSelect(product)} key={index}>
                              <ListItemAvatar>
                                <div className='product-img'>
                                  <img src={product?.image} alt={product?.name} />
                                </div>
                              </ListItemAvatar>
                              <ListItemText primary={product?.name} />
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label className='unastrik visibility-hidden-label'>1</label>
                    <Button className='load-btn'><Typography className='btn-text'>Load Product</Typography></Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label >Product Name</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='productName'
                      placeholder='Product Name'
                      value={selectedProduct?.name}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label >SKU</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='sku'
                      placeholder='SKU'
                      value={selectedProduct?.sku}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label >Selling Price</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='sellingPrice'
                      placeholder='Selling Price'
                      value={updatedPrice}
                      onChange={handlePriceChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label >Quantity</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='quantity'
                      placeholder='Quantity'
                      value={updatedQuantity}
                      onChange={handleQuantityChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label className='unastrik'>Weight</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='weight'
                      placeholder='Weight'
                      value={selectedProduct?.weight}
                      disabled
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box className='text-field-wrap'>
                    <label >EAN/UPC (Bar code No. Of product)</label>
                    <TextField
                      variant="outlined"
                      className='input-field'
                      name='barcodeNumber'
                      placeholder='EAN/UPC (Bar code No. Of product)'
                      value={selectedProduct?.ean}
                      disabled
                    />
                  </Box>
                </Grid>
              </Grid>
              {
                selectedProduct?.image_gallery && (
                  <Box className='product-images'>
                    <label className='unastrik' >Product Images</label>
                    <div>
                      {selectedProduct?.image_gallery && selectedProduct?.image_gallery?.map((image: any, index: any) => (
                        <div key={index}>
                          <Grid item xs={6} md={6} lg={6}>
                            <div className='img-wrapper'>
                              <div className='img-name'>
                                <img src={image.url} alt={`Product ${index + 1}`} />
                              </div>
                            </div>
                          </Grid>
                          <Divider />
                        </div>
                      ))}
                    </div>
                  </Box>
                )
              }
              {/* <Box className='product-images'>
                <label className='unastrik' >Product Images</label>
                <div>
                  {selectedProduct?.image_gallery && selectedProduct?.image_gallery?.map((image: any, index: any) => (
                    <div key={index}>
                      <Grid item xs={6} md={6} lg={6}>
                        <div className='img-wrapper'>
                          <div className='img-name'>
                            <img src={image.url} alt={`Product ${index + 1}`} />
                          </div>
                        </div>
                      </Grid>
                      <Divider />
                    </div>
                  ))}
                </div>
              </Box> */}
            </div>
            <div className='btn-group'>
              <Button className='save-btn' onClick={handleAddProduct}><Typography className='btn-text'>Save Product</Typography></Button>
              <Button className='cancel-btn'><Typography className='btn-text'>Cancel</Typography></Button>
            </div>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box className='bulk-upload'>
            {<Toaster />}
            <div className='main'>
              <List>
                <ListItem className='wrapper'>
                  <ListItemText className='list-text'>
                    <Typography className='text'>1. Sample Download</Typography>
                  </ListItemText>
                  <ListItemIcon className='list-icon' onClick={handleSampleDownload}>
                    <CloudUploadIcon className='svg-icon' />
                  </ListItemIcon>
                </ListItem>
                <Divider className='divider' />
                <ListItem className='wrapper'>
                  <ListItemText className='list-text'>
                    <Typography className='text'>2. Attach Bulk Sheet</Typography>
                  </ListItemText>
                  <ListItemIcon className='list-icon'>
                    <Button className='sheet-btn' onClick={handleBulkSheetUpload}><Typography variant='h5'>Upload Bulk Sheet</Typography></Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                  </ListItemIcon>
                </ListItem>
                <Divider className='divider' />
              </List>
            </div>
            <div className='btn-group'>
              <Button className='save-btn' onClick={uploadBulkSheet}><Typography className='btn-text' >Update Product</Typography></Button>
              <Button className='cancel-btn'><Typography className='btn-text'>Cancel</Typography></Button>
            </div>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {
            updateProductDataError ? (
              <p className='error-text'>{errorUpdateProductData}</p>
            ) : (
              updateProductDataLoading ? (
                <CircularProgress className='loader' />
              ) : (
                <Box className="edit-price">
                  {<Toaster />}
                  <div className='main'>
                    <div className='btn-group'>
                      <TextField placeholder='Search' name="search_product" id="search_product" onChange={handleSetSearchTerms}/>
                      <Button className='cancel-btn' style={{height: "50px", backgroundColor: "#ddd"}} onClick={handleSearchProduct}><Typography className='btn-text'>Search</Typography></Button>
                    </div>
                    <DashBoardTable headCells={headCellsForEditPrice} rows={editPriceTableData} ButtonActions={additionalActionsForeditPrice} onValueChange={handleEditPriceQuantityChange} />
                  </div>
                  {/* <div className='btn-group'>
              <Button className='save-btn'><Typography className='btn-text'>Update Product</Typography></Button>
              <Button className='cancel-btn'><Typography className='btn-text'>Cancel</Typography></Button>
              <Button variant="contained" color="primary" onClick={() => action.onClick(row)} className={`custom-btn-${index}`}>
                          {action.buttonLabel}
                        </Button>
            </div> */}
                  <Box sx={{ pt: 5 }}>
                    <Stack spacing={2}>
                      <Pagination
                        count={totalPage ? totalPage : 10}
                        shape="rounded"
                        onChange={handlePageChange}
                        defaultPage={selectedPage}
                      />
                    </Stack>
                  </Box>
                </Box>
              )
            )
          }
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Box className="bulk-price">
            {<Toaster />}
            <div className='main'>
              <List>
                <ListItem className='wrapper'>
                  <ListItemText className='list-text'>
                    <Typography className='text'>1. Download All Product</Typography>
                  </ListItemText>
                  <ListItemIcon className='list-icon' onClick={handleDownloadAllProducts}>
                    <CloudUploadIcon className='svg-icon' />
                  </ListItemIcon>
                </ListItem>
                <Divider className='divider' />
                <ListItem className='wrapper'>
                  <ListItemText className='list-text'>
                    <Typography className='text'>2. Upload All Product</Typography>
                  </ListItemText>
                  <ListItemIcon className='list-icon'>
                    <Button className='sheet-btn' onClick={handleBulkSheetUpdate}><Typography variant='h5'>Upload Product</Typography></Button>
                    <input
                      ref={InputRefForBulkUpdate}
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleFileForBulkUpdate}
                    />
                  </ListItemIcon>
                </ListItem>
                <Divider className='divider' />
              </List>
            </div>
            <div className='btn-group'>
              <Button className='save-btn' onClick={uploadBulkUpdateSheet}><Typography className='btn-text'>Upload Product</Typography></Button>
              <Button className='cancel-btn'><Typography className='btn-text'>Cancel</Typography></Button>
            </div>

          </Box>
        </CustomTabPanel>
      </Box>
      {/* MobileContent */}
      <Box className='mobile__view__block'>
        {
          selectedMenuItem === 'Create New Product' && (
            <Box className='add-product-tab '>
              {<Toaster />}
              <div className='main-body'>
                <Grid className='company-upper' container spacing={2} >
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label >Product Name</label>
                      <TextField
                        variant="outlined"
                        className='input-field'
                        name='productName'
                        placeholder='Product Name'
                        value={dataforNewProduct.name}
                        onChange={(e: any) => handleDataForNewProduct(e, 'name')}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label >SKU</label>
                      <TextField
                        variant="outlined"
                        className='input-field'
                        name='sku'
                        placeholder='SKU'
                        value={dataforNewProduct.sku}
                        onChange={(e: any) => handleDataForNewProduct(e, 'sku')}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label >Selling Price</label>
                      <TextField
                        variant="outlined"
                        className='input-field'
                        name='sellingPrice'
                        placeholder='Selling Price'
                        value={dataforNewProduct.price}
                        onChange={(e: any) => handleDataForNewProduct(e, 'price')}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label >Quantity</label>
                      <TextField
                        variant="outlined"
                        className='input-field'
                        name='quantity'
                        placeholder='Quantity'
                        value={dataforNewProduct.qty}
                        onChange={(e: any) => handleDataForNewProduct(e, 'qty')}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label className='unastrik'>Weight</label>
                      <TextField
                        variant="outlined"
                        className='input-field'
                        name='weight'
                        placeholder='Weight'
                        value={dataforNewProduct.weight}
                        onChange={(e: any) => handleDataForNewProduct(e, 'weight')}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label >EAN/UPC (Bar code No. Of product)</label>
                      <TextField
                        variant="outlined"
                        className='input-field'
                        name='barcodeNumber'
                        placeholder='EAN/UPC (Bar code No. Of product)'
                        value={dataforNewProduct.ean}
                        onChange={(e: any) => handleDataForNewProduct(e, 'ean')}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label className='unastrik'>Short Description </label>
                      <TextField
                        placeholder="Short Description"
                        required
                        fullWidth
                        multiline
                        rows={4}
                        maxRows={10}
                        value={dataforNewProduct.shortDescription}
                        onChange={(e: any) => handleDataForNewProduct(e, 'shortDescription')}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label className='unastrik'> Description </label>
                      <TextField
                        placeholder="Description"
                        required
                        fullWidth
                        multiline
                        rows={4}
                        maxRows={10}
                        value={dataforNewProduct.description}
                        onChange={(e: any) => handleDataForNewProduct(e, 'description')}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                  <Grid item xs={12} md={12} lg={12}>
                    <h4 className='block-title'>Upload Images</h4>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='upload-img'>
                      <input
                        type="file"
                        id="uploadfile"
                        multiple
                        onChange={handleProductFileUpload} />
                      <label htmlFor="uploadfile" className="btn-1">
                        <CloudUploadIcon />
                        upload file</label>
                    </Box>
                  </Grid>
                  {uploadedFiles && uploadedFiles.length > 0 && (
                    <Grid item xs={12} md={6} lg={6}>
                      <h4>Uploaded Images</h4>
                      {uploadedFiles?.map((file, index) => (
                        <div className="status-upload-img" key={index}>
                          <div className="status-upload-block">
                            <div className="upload-img-item">
                              <img src={file.base64String} alt={`Uploaded file ${index}`} style={{ width: "50px", height: "50px" }} />
                            </div>
                            <div className="progress-block">
                              <div className="progress-block-label">
                                <div className="img-name">{file.name}</div>
                                <div className="img-close" ><CancelIcon onClick={() => removeProductImage(index)} /></div>
                              </div>
                            </div>
                          </div>
                          <div className="select-items">
                            <FormControlLabel
                              required
                              control={
                                <Checkbox
                                  checked={selectedFileIndex === index}
                                  onChange={() => handleCheckboxChange(index)}
                                />
                              }
                              label="Select Main Photo"
                            />
                          </div>
                          <hr />
                        </div>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </div>
              <div className='btn-group'>
                <Button className='save-btn' onClick={submitCreateProductData}><Typography className='btn-text'>Save Product</Typography></Button>
                <Button className='cancel-btn'><Typography className='btn-text'>Cancel</Typography></Button>
              </div>

            </Box>
          )
        }
        {
          selectedMenuItem === 'Add a Product' && (
            <Box className='add-product-jebelzId'>
              {<Toaster />}
              <div className="main">
                <Grid className='company-upper' container spacing={2} sx={{ padding: '30px' }}>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap product-search-box'>
                      <label className='unastrik'>Search by product</label>
                      <TextField
                        variant="outlined"
                        className='input-field'
                        name='jebelzId'
                        placeholder='Search by product'
                        value={searchTerm}
                        onChange={(e: any) => setSearchTerm(e.target.value)}
                        // onFocus={() => setSearchResultModalShow(true)}
                        onFocus={() => {
                          if (searchTerm.trim() === "") {
                            setSearchResultModalShow(false);
                          } else {
                            setSearchResultModalShow(true);
                          }
                        }}
                      />
                      {searchResultModalShow && searchProductData && searchProductData?.sellerProducts && (
                        <div className="product-search-result">
                          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {searchProductData?.sellerProducts?.map((product: any, index: number) => (
                              <ListItem onClick={() => handleProductSelect(product)} key={index}>
                                <ListItemAvatar>
                                  <div className='product-img'>
                                    <img src={product?.image} alt={product?.name} />
                                  </div>
                                </ListItemAvatar>
                                <ListItemText primary={product?.name} />
                              </ListItem>
                            ))}
                          </List>
                        </div>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label className='unastrik visibility-hidden-label'>1</label>
                      <Button className='load-btn'><Typography className='btn-text'>Load Product</Typography></Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label >Product Name</label>
                      <TextField
                        variant="outlined"
                        className='input-field'
                        name='productName'
                        placeholder='Product Name'
                        value={selectedProduct?.name}
                        disabled
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label >SKU</label>
                      <TextField
                        variant="outlined"
                        className='input-field'
                        name='sku'
                        placeholder='SKU'
                        value={selectedProduct?.sku}
                        disabled
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label >Selling Price</label>
                      <TextField
                        variant="outlined"
                        className='input-field'
                        name='sellingPrice'
                        placeholder='Selling Price'
                        value={updatedPrice}
                        onChange={handlePriceChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label >Quantity</label>
                      <TextField
                        variant="outlined"
                        className='input-field'
                        name='quantity'
                        placeholder='Quantity'
                        value={updatedQuantity}
                        onChange={handleQuantityChange}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label className='unastrik'>Weight</label>
                      <TextField
                        variant="outlined"
                        className='input-field'
                        name='weight'
                        placeholder='Weight'
                        value={selectedProduct?.weight}
                        disabled
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box className='text-field-wrap'>
                      <label >EAN/UPC (Bar code No. Of product)</label>
                      <TextField
                        variant="outlined"
                        className='input-field'
                        name='barcodeNumber'
                        placeholder='EAN/UPC (Bar code No. Of product)'
                        value={selectedProduct?.ean}
                        disabled
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Box className='product-images'>
                  {
                    selectedProduct?.image_gallery && (
                      <>
                        <label className='unastrik' >Product Images</label>
                        <div>
                          {selectedProduct?.image_gallery && selectedProduct?.image_gallery?.map((image: any, index: any) => (
                            <div key={index}>
                              <Grid item xs={6} md={6} lg={6}>
                                <div className='img-wrapper'>
                                  <div className='img-name'>
                                    <img src={image.url} alt={`Product ${index + 1}`} />
                                  </div>
                                </div>
                              </Grid>
                              <Divider />
                            </div>
                          ))}
                        </div>
                      </>
                    )
                  }
                </Box>
              </div>
              <div className='btn-group'>
                <Button className='save-btn' onClick={handleAddProduct}><Typography className='btn-text'>Save Product</Typography></Button>
                <Button className='cancel-btn'><Typography className='btn-text'>Cancel</Typography></Button>
              </div>
            </Box>
          )
        }
        {
          selectedMenuItem === 'Bulk Upload' && (
            <Box className='bulk-upload'>
              {<Toaster />}
              <div className='main'>
                <List>
                  <ListItem className='wrapper'>
                    <ListItemText className='list-text'>
                      <Typography className='text'>1. Sample Download</Typography>
                    </ListItemText>
                    <ListItemIcon className='list-icon' onClick={handleSampleDownload}>
                      <CloudUploadIcon className='svg-icon' />
                    </ListItemIcon>
                  </ListItem>
                  <Divider className='divider' />
                  <ListItem className='wrapper'>
                    <ListItemText className='list-text'>
                      <Typography className='text'>2. Attach Bulk Sheet</Typography>
                    </ListItemText>
                    <ListItemIcon className='list-icon'>
                      <Button className='sheet-btn' onClick={handleBulkSheetUpload}><Typography variant='h5'>Upload Bulk Sheet</Typography></Button>
                      <input
                        ref={fileInputRef}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                    </ListItemIcon>
                  </ListItem>
                  <Divider className='divider' />
                </List>
              </div>
              <div className='btn-group'>
                <Button className='save-btn' onClick={uploadBulkSheet}><Typography className='btn-text'>Update Product</Typography></Button>
                <Button className='cancel-btn'><Typography className='btn-text'>Cancel</Typography></Button>
              </div>
            </Box>
          )
        }
        {
          selectedMenuItem === 'Easy Edit Price & Quantity' && (
            <Box className="edit-price">
              <div className='main'>
                {updateProductDataError ? (
                  <p className='error-text'>{errorUpdateProductData}</p>
                ) : (
                  updateProductDataLoading ? (
                    <CircularProgress className='loader' />
                  ) : (
                    <>
                      {<Toaster />}
                      <MobileTable headCells={headCellsForEditPrice} rows={editPriceTableData} ButtonActions={additionalActionsForeditPrice} onValueChange={handleEditPriceQuantityChange} />
                    </>
                  )
                )}
              </div>
            </Box>
          )
        }
        {
          selectedMenuItem === 'Bulk Price & Quantity Update' && (
            <Box className="bulk-price">
              {<Toaster />}
              <div className='main'>
                <List>
                  <ListItem className='wrapper'>
                    <ListItemText className='list-text'>
                      <Typography className='text'>1. Download All Product</Typography>
                    </ListItemText>
                    <ListItemIcon className='list-icon' onClick={handleDownloadAllProducts}>
                      <CloudUploadIcon className='svg-icon' />
                    </ListItemIcon>
                  </ListItem>
                  <Divider className='divider' />
                  <ListItem className='wrapper'>
                    <ListItemText className='list-text'>
                      <Typography className='text'>2. Upload All Product</Typography>
                    </ListItemText>
                    <ListItemIcon className='list-icon'>
                      <Button className='sheet-btn' onClick={handleBulkSheetUpdate}><Typography variant='h5'>Upload Product</Typography></Button>
                      <input
                        ref={InputRefForBulkUpdate}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileForBulkUpdate}
                      />
                    </ListItemIcon>
                  </ListItem>
                  <Divider className='divider' />
                </List>
              </div>
              <div className='btn-group'>
                <Button className='save-btn' onClick={uploadBulkUpdateSheet}><Typography className='btn-text'>Upload Product</Typography></Button>
                <Button className='cancel-btn'><Typography className='btn-text'>Cancel</Typography></Button>
              </div>
            </Box>
          )
        }
      </Box>



    </div>
  )
}

export default Product