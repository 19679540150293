import React, { useEffect } from 'react'
import "./Register.css"
import MainForm from '../../../Components/Create_Seller_Acc_Form/MainForm'

const Register = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='register-main'>
        <MainForm/>
    </div>
  )
}

export default Register