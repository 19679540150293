import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import './NewSidebar.scss';
import { List } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SpeedIcon from '@mui/icons-material/Speed';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { settingsPageQuery } from '../../Operations/Queries';
import { useMutation, useQuery } from '@apollo/client';
import * as mutations from "../../Operations/Mutations";
import { CountryContext } from '../..';
import { queries } from '@testing-library/react';

const Sidebar = () => {
   const title = window.location.pathname.split("/");
   console.log("title:", title)
   let topbarHeaderName;
   if (title.length === 4 ) {
      topbarHeaderName = title[2].charAt(0).toUpperCase() + title[2].slice(1)
   } else {
      topbarHeaderName = title[title.length - 1].charAt(0).toUpperCase() + title[title.length - 1].slice(1)
   }
   console.log("topbarHeaderName:", topbarHeaderName)
   const selectedItem = topbarHeaderName;
   const navigate = useNavigate()
   const loggedInToken = localStorage.getItem("loggedInCust");
   console.log("token from localStorage sidebar--", loggedInToken)

   const {store} = useContext(CountryContext);

   const [revokeCustomertoken] = useMutation(mutations.logoutMutation.REVOKE_CUSTOMER_TOKEN,
      {
         context: {
            headers: {
               Authorization: loggedInToken
                  ? `Bearer ${JSON.parse(loggedInToken)}`
                  : "",
               Connection: "keep-alive",
               store: store,
            },
         },
      });

   const { data: SettingsMainData, error: settingsError, loading: settingsLoading } = useQuery(settingsPageQuery.SETTINGS_MAINPAGE, {
      context: {
         headers: {
            Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
            Connection: "keep-alive",
            store: store,
         },
         onerror(error: any) {
            console.log("error: ",error)
            if(error=="Seller is not loggedin") {
               handleLogOut();
            }
         }
      },
   });
   
   const settingsData = SettingsMainData?.sellerData;

   const handleLogOut = async () => {
      console.log("logout clicked")
      try {
         const response = await revokeCustomertoken();
         console.log("response: ", response.data.revokeCustomerToken.result);
         localStorage.removeItem('selectedMenuItem');
         navigate("/")
      } catch (error) {
         console.error('Error revoking token:', error);
         localStorage.removeItem('selectedMenuItem');
         navigate("/")
      }
   }
   if(settingsData==undefined) {
      
   }
   console.log("settingsData",settingsData);
   return (
      <>
         <div className='sidebar_main'>
            <div className='offcanvas offcanvas-start' tab-index="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
               <div className='offcanvas_header'>
                  <div className='header_logo'>
                     <img src={require("../../assets/images/jebelz-seller-logo.jpg")} alt='seller-logo' />
                     <span className='logo_txt text-center'>{`${settingsData?.companyInformation[0]?.firstname} ${settingsData?.companyInformation[0]?.lastname}`}</span>
                  </div>
               </div>

               <div className='offcanvas-body'>
                  <div className='inner_sidebar h-100'>

                     <div className='menu_list h-100' id="sidebar">
                        <nav className='h-100' aria-label="main mailbox folders">
                           <List className='top-menu h-100'>
                              <ListItem
                                 disablePadding
                                 className={selectedItem === 'Dashboard' ? 'active' : ''}
                                 // onClick={() => handleMenuItem('Dashboard')}
                                 component={Link}
                                 to="/dashboard"
                              >
                                 <ListItemButton >
                                    <ListItemIcon className='menu-icon'>
                                       <SpeedIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Dashboard" className='menu-name' />
                                 </ListItemButton>
                              </ListItem>
                              <ListItem
                                 disablePadding
                                 className={selectedItem === 'Product' ? 'active' : ''}
                                 // onClick={() => handleMenuItem('Product')}
                                 component={Link}
                                 to="/dashboard/product"
                              >
                                 <ListItemButton >
                                    <ListItemIcon className='menu-icon'>
                                       <ProductionQuantityLimitsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Product" className='menu-name' />
                                 </ListItemButton>
                              </ListItem>
                              <ListItem
                                 disablePadding
                                 className={selectedItem === 'Orders' ? 'active' : ''}
                                 // onClick={() => handleMenuItem('Orders')}
                                 component={Link}
                                 to="/dashboard/orders"
                              >
                                 <ListItemButton >
                                    <ListItemIcon className='menu-icon'>
                                       <EventNoteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary=" Orders" className='menu-name' />
                                 </ListItemButton>
                              </ListItem>
                              <ListItem
                                 disablePadding
                                 className={selectedItem === 'Returns' ? 'active' : ''}
                                 component={Link}
                                 to="/dashboard/returns"
                              >
                                 <ListItemButton >
                                    <ListItemIcon className='menu-icon'>
                                       <AssignmentReturnIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Returns" className='menu-name' />
                                 </ListItemButton>
                              </ListItem>
                              <ListItem
                                 disablePadding
                                 className={selectedItem === 'Payment' ? 'active' : ''}
                                 // onClick={() => handleMenuItem('Payment')}
                                 component={Link}
                                 to="/dashboard/payment"
                              >
                                 <ListItemButton >
                                    <ListItemIcon className='menu-icon'>
                                       <ReceiptIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Payment" className='menu-name' />
                                 </ListItemButton>
                              </ListItem>
                              <ListItem
                                 disablePadding
                                 className={selectedItem === 'Settings' ? 'active' : ''}
                                 // onClick={() => handleMenuItem('Settings')}
                                 component={Link}
                                 to="/dashboard/settings"
                              >
                                 <ListItemButton >
                                    <ListItemIcon className='menu-icon'>
                                       <SettingsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary=" Settings" className='menu-name' />
                                 </ListItemButton>
                              </ListItem>
                              
                           </List>
                           <List className='bottom-menu'>
                                 <ListItem disablePadding >
                                    <ListItemButton onClick={handleLogOut}>
                                       <ListItemIcon className='menu-icon'>
                                          <LogoutIcon />
                                       </ListItemIcon>
                                       <ListItemText primary="Log Out" className='menu-name' />
                                    </ListItemButton>
                                 </ListItem>
                              </List>
                        </nav>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Sidebar
