import "../../../../src/mainseller.scss";
import { Divider } from '@mui/material'
import React, { useEffect } from 'react'
import InfoPoints from "../../../Components/FAQ_Info_Points/InfoPoints"

const Faq = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const faqSections = [
    {
      title: "Payment Terms",
      detailText: "Your payment terms detail text goes here."
    },
    {
      title: "Referral Fee",
      detailText: "Commision percentage is 14% for Coffee Equipment and 19% for Coffee Beans and other consumables."
    },
    {
      title: "Return Policy",
      detailText: "Your return policy detail text goes here."
    },
    {
      title: "Listing Process",
      detailText: "Your Listing Process detail text goes here."
    },
    {
      title: "Accountancy Process",
      detailText: "Your Accountancy Process detail text goes here."
    },
    {
      title: "Shipping Fee",
      detailText: "Your Shipping Fee detail text goes here."
    },
    {
      title: "Warranty",
      detailText: "Your Warranty detail text goes here."
    },
    {
      title: "Installation",
      detailText: "Your Installation detail text goes here."
    },
  ];

  return (
    <div className='faq-main center-div'>
      <div className='main-head' >
        <div className='title'>
          <Divider className='title-line'/>
          <h1>Seller Frequently Asked Questions</h1>
          <Divider className='title-line'/>
        </div>
      </div>
      <div className="faq-bottom-section">
        {/* <InfoPoints/> */}
        {faqSections.map((section, index) => (
          <div key={index} className="points-section">
            <InfoPoints  title={section.title} detailText={section.detailText}/>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Faq