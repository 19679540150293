// Youtube Component: Displays Jebelz Seller Training Videos
import { useState } from 'react';
import { Container, Typography, Grid, Box, Modal, Backdrop, Fade, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function SellerTraning() {
    const [open, setOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const videos = [
        
        { id: '1', url: 'https://www.youtube.com/embed/DiMmjJM0lq0', title: 'How to Register on jebelz as a Sellers ' },
        { id: '2', url: 'https://www.youtube.com/embed/lETBTDsEBCM', title: 'How to Create New Product on jebelz as a Seller' },
        { id: '3', url: 'https://www.youtube.com/embed/C16ZDjvaz1o', title: 'How to Link a Product on jebelz as a Seller' },
        { id: '4', url: 'https://www.youtube.com/embed/Ou7g15yPUns', title: 'How to Easy Edit Price and Qty on jebelz as a seller' },
        { id: '5', url: 'https://www.youtube.com/embed/hP77yNAVLIU', title: 'How to Bulk Update Price and qty on jebelz as a seller' },
        { id: '6', url: 'https://www.youtube.com/embed/UWCwTDtLFJk', title: 'How to do Accept/Cancel Orders on jebelz as a Seller' },
        { id: '7', url: 'https://www.youtube.com/embed/Yw8MTyVCAMc', title: 'How to Order Processes on jebelz as a Seller' },
    ];

    const handleOpen = (url: any,) => {
        setVideoUrl(url);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setVideoUrl('');
    };

    return (
        <div className="Traning-video">
            <Container maxWidth="xl">
                <Box  sx={{ p: 2, ml:2, mb:8}}>
                    <Typography variant="h3" gutterBottom align="center" padding={2}>
                        Seller Training
                    </Typography>
                    <Grid container spacing={3}>
                        {videos.map((video) => (
                            <Grid item xs={12} sm={4} md={4} lg={3} key={video.id}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        paddingBottom: '56.25%',
                                        height: 0,
                                        overflow: 'hidden',
                                        '& iframe': {
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: "8px"
                                        },
                                    }}
                                >
                                    <iframe rel='0'
                                    
                                        src={video.url}
                                        title={`YouTube video player ${video.id}`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                    <Box
                                        sx={{
                                            borderRadius: "8px",
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            color: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            opacity: 0,
                                            transition: 'opacity 0.3s',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                opacity: 1,
                                            },
                                        }}
                                        onClick={() => handleOpen(video.url)}
                                    >
                                        <Typography variant="h6">Jebelz Seller</Typography>
                                    </Box>
                                </Box>
                                <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>{video.title}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '50%',
                                    bgcolor: 'background.paper',
                                    boxShadow: 24,
                                    p: 4,
                                }}
                            >
                                <IconButton
                                    aria-label="close"
                                    onClick={handleClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        paddingBottom: '56.25%',
                                        height: 0,
                                        overflow: 'hidden',
                                        '& iframe': {
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                        },
                                    }}
                                >
                                    <iframe
                                        src={videoUrl}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="controls=0; cc_load_policy=0; rel=0; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </Box>
                            </Box>
                        </Fade>
                    </Modal>
                </Box>
            </Container>
        </div>
    );
}

export default SellerTraning;
